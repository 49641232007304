import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Header, Loading } from "../component";
import { supbaseWrapper } from "../../supabaseClient";
import useStore from "../../commonStore";
import RecipeTips from "./recipeTips";

const RecipeDetail = () => {
  const { commonMealDetailObj, setCommonMealDetailObj } = useStore();
  const [recipe, setRecipe] = useState(null);
  const { getRecipeDetail } = supbaseWrapper();
  const location = useLocation();
  // location에서 surveyId 추출
  const surveyId = location.state?.surveyId;
  const navigate = useNavigate();
  const [error, setError] = useState(null); // 오류 상태 추가
  const [id, setId] = useState(null); // id 상태 추가

  const getDetail = useCallback(async (id) => {
    try {
      const res = await getRecipeDetail(id);
      if (!res.data) {
        throw new Error("No data found");
      }
      return res.data[0];
    } catch (err) {
      throw err;
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const idFromQuery =
      searchParams.get("id") || (location.state && location.state.id);

    console.log("Recipe ID:", idFromQuery); // 디버깅 로그 추가

    if (!idFromQuery) {
      navigate("/error");
    } else {
      setId(idFromQuery);
    }
  }, [location, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return; // id가 설정된 후에만 데이터 불러오기

      console.log("Recipe ID:", id);

      try {
        let commonMealDetail = commonMealDetailObj[id];
        if (commonMealDetail) {
          setRecipe(commonMealDetail);
        } else {
          let searchData = await getDetail(id);
          searchData = {
            ...searchData,
            instructions: JSON.parse(searchData.instructions),
            ingredients: JSON.parse(searchData.ingredients),
          };
          setCommonMealDetailObj((prev) => ({
            ...prev,
            [searchData.id]: searchData,
          }));
          setRecipe(searchData);
        }
      } catch (e) {
        console.error("엘라스틱 서치 오류 발생 : ", e);
        setError(e.message); // 오류 설정
      }
    };
    fetchData();
  }, [id, getDetail, setCommonMealDetailObj]);

  const processedInstructions = [];
  let currentInstruction = null;
  let dashItems = [];

  recipe?.instructions?.forEach((instruction) => {
    if (instruction.startsWith("-") || instruction.startsWith("*")) {
      dashItems.push(instruction.slice(1).trim());
    } else {
      if (currentInstruction) {
        if (dashItems.length > 0) {
          currentInstruction.sub.push(dashItems.join(", "));
          dashItems = [];
        }
        processedInstructions.push(currentInstruction);
      }
      currentInstruction = { main: instruction, sub: [] };
    }
  });

  if (currentInstruction) {
    if (dashItems.length > 0) {
      currentInstruction.sub.push(dashItems.join(", "));
    }
    processedInstructions.push(currentInstruction);
  }

  const ingredientsArray = Object.entries(recipe?.ingredients || {});
  const midIndex = Math.ceil(ingredientsArray.length / 2);
  const leftIngredients = ingredientsArray.slice(0, midIndex);
  const rightIngredients = ingredientsArray.slice(midIndex);

  if (error) {
    return <div>Error: {error}</div>; // 오류 메시지 표시
  }

  if (!recipe) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <div className="flex flex-col items-center pt-20 px-3 justify-center w-full md:w-1/2 lg:w-2/5 bg-white relative h-screen overflow-auto">
        <Header showBackButton={true} />

        <div className="w-full justify-start flex flex-col items-start gap-3 h-full overflow-y-auto">
          <div id="recipe_img" className="w-full pb-[100%] relative">
            <img
              src={recipe.img_url}
              alt={recipe.title}
              className="absolute top-0 left-0 w-full h-full object-contain rounded-xl"
            />
          </div>

          <h1 className="text-lg md:text-xl font-bold text-left text-gray-800">
            {recipe.title}
          </h1>

          <div className="flex flex-col items-start w-full">
            {recipe.author && (
              <div className="flex items-center w-full">
                <span className="font-bold text-gray-700 text-sm md:text-base">
                  작성자 :
                </span>
                <span className="ml-2 text-gray-600 text-xs md:text-sm">
                  {recipe.author}
                </span>
              </div>
            )}
          </div>
          <div className="flex w-full bg-amber-100 shadow-md rounded-lg my-2">
            {recipe && <RecipeTips itemId={id} surveyId={surveyId} />}
          </div>
          <div className="w-full flex justify-end mb-4">
            <div className="flex items-center me-3">
              <span className="font-bold text-sm text-amber-500 md:text-base">
                난이도
              </span>
              <span className="ml-1 text-sm md:text-base">
                {recipe.level || "*"}
              </span>
            </div>
            <div className="flex items-center">
              <span className="font-bold text-sm md:text-base text-amber-500">
                조리시간
              </span>
              <span className="ml-1 text-sm md:text-base">
                {recipe.cookingTime || "*"}
              </span>
            </div>
          </div>
          <div className="w-full flex flex-col items-center gap-5">
            <div className="w-full flex justify-between items-center border-b border-zinc-500 pb-2">
              <span className="text-neutral-700 text-base md:text-lg font-bold">
                재료
              </span>
              <span className="text-neutral-700 text-xs md:text-sm">
                {recipe.serving || "*"}인분 기준
              </span>
            </div>
            <div className="w-full flex flex-col gap-4">
              {leftIngredients.map(([ingredient, amount]) => (
                <div
                  key={ingredient}
                  className="w-full flex justify-start items-center border-b border-zinc-100 pb-1"
                >
                  <span className="text-gray-700 text-xs md:text-sm w-1/2">
                    {ingredient}
                  </span>
                  <span className="text-gray-700 text-xs md:text-sm w-1/2 text-left">
                    {amount}
                  </span>
                </div>
              ))}
              {rightIngredients.map(([ingredient, amount]) => (
                <div
                  key={ingredient}
                  className="w-full flex justify-start items-center border-b border-zinc-100 pb-1"
                >
                  <span className="text-gray-700 text-xs md:text-sm w-1/2">
                    {ingredient}
                  </span>
                  <span className="text-gray-700 text-xs md:text-sm w-1/2 text-left">
                    {amount}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="w-full flex-col justify-start items-start">
            <div className="text-gray-700 text-base md:text-xl font-bold mb-5 mt-14">
              조리순서
            </div>
            <div className="flex flex-col gap-2 mb-10 ">
              {processedInstructions.map((instruction, index) => (
                <div key={index} className="w-full flex items-start">
                  <span className="text-gray-700 text-lg font-bold">
                    {index + 1}.{" "}
                  </span>
                  <div className="w-full flex flex-col items-start">
                    <span className="text-gray-700 mt-2 font-semibold text-sm mb-2 md:text-base ml-2">
                      {instruction.main}
                    </span>

                    {instruction.sub.length > 0 && (
                      <ul className="ml-8 list-disc">
                        {instruction.sub.map((subItem, subIndex) => (
                          <li
                            key={subIndex}
                            className="text-gray-500 text-xs md:text-sm"
                          >
                            {subItem}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {recipe.ori_url && (
            <p className="w-full text-right">
              <a
                href={recipe.ori_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 text-xs md:text-sm underline"
              >
                원본 보러가기
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecipeDetail;
