import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useStore from "../../commonStore";
import { supbaseWrapper } from "../../supabaseClient";
import { OnboardingHeader } from "../component";

const commonIngredients = [
  "새우",
  "달걀",
  "견과류",
  "땅콩",
  "홍합",
  "굴",
  "복숭아",
  "키위",
  "고등어",
  "설탕",
];

const allIngredients = [
  "우유",
  "치즈",
  "요거트",
  "버터",
  "아이스크림",
  "달걀",
  "계란 흰자",
  "계란 노른자",
  "땅콩",
  "땅콩 버터",
  "땅콩 오일",
  "아몬드",
  "호두",
  "피칸",
  "캐슈",
  "피스타치오",
  "헤이즐넛",
  "대구",
  "참치",
  "연어",
  "고등어",
  "새우",
  "게",
  "랍스터",
  "가재",
  "조개",
  "홍합",
  "굴",
  "오징어",
  "문어",
  "밀가루",
  "빵",
  "파스타",
  "시리얼",
  "대두",
  "두부",
  "간장",
  "대두유",
  "템페",
  "참깨",
  "해바라기씨",
  "호박씨",
  "키위",
  "바나나",
  "아보카도",
  "복숭아",
  "사과",
  "체리",
  "딸기",
  "토마토",
  "셀러리",
  "감자",
  "당근",
  "옥수수",
  "고기",
  "양파",
  "마늘",
  "버섯",
  "생강",
  "고구마",
  "양배추",
  "상추",
  "호박",
  "고추",
  "파프리카",
  "콩나물",
  "시금치",
  "깻잎",
  "오이",
  "고추장",
  "된장",
  "소금",
  "후추",
  "설탕",
  "식초",
  "참기름",
  "깨",
  "고추기름",
];

const consonants = [
  "ㄱ",
  "ㄲ",
  "ㄴ",
  "ㄷ",
  "ㄸ",
  "ㄹ",
  "ㅁ",
  "ㅂ",
  "ㅃ",
  "ㅅ",
  "ㅆ",
  "ㅇ",
  "ㅈ",
  "ㅉ",
  "ㅊ",
  "ㅋ",
  "ㅌ",
  "ㅍ",
  "ㅎ",
];

const getConsonant = (char) => {
  const offset = 44032;
  const ch = char.charCodeAt(0) - offset;
  if (ch < 0 || ch > 11171) return char;
  const index = Math.floor(ch / 588);
  return consonants[index];
};

const isInitialConsonant = (char) => {
  return consonants.includes(char);
};

const currentStep = 5;
const ExcludedIngredients = () => {
  const { upsertSurvey } = supbaseWrapper();
  const { onboardingValue, setOnboardingValue } = useStore();
  const [selectedIngredients, setSelectedIngredients] = useState(
    onboardingValue.excludedIngredients || []
  );
  const [search, setSearch] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const searchInputRef = useRef(null);

  const updateData = useCallback(
    async (params) => {
      await upsertSurvey(params);
    },
    []
  );

  useEffect(() => {
    if (onboardingValue.startIntro !== true) {
      navigate("/");
    } else {
      if (!location.state || location.state.fromInputCheck !== true) {
        let params = {...onboardingValue, lastStep: currentStep}
        updateData(params);
        setOnboardingValue(params);
      }
    }
  }, []);

  const handleIngredientSelect = (ingredient) => {
    if (!selectedIngredients.includes(ingredient)) {
      setSelectedIngredients([...selectedIngredients, ingredient]);
    }
    setSearch("");
    setActiveIndex(0);
  };

  const handleIngredientRemove = (ingredient) => {
    setSelectedIngredients(
      selectedIngredients.filter((item) => item !== ingredient)
    );
  };

  const handleNextClick = () => {
    let params = { ...onboardingValue, excludedIngredients: selectedIngredients };
    updateData(params);
    setOnboardingValue(params);

    if (location.state && location.state.fromInputCheck === true) {
      navigate("/inputCheck");
    } else {
      navigate(`/inputCheck`);
    }
  };

  const getFilteredIngredients = () => {
    if (!search) return [];
    const searchLower = search.toLowerCase();

    return allIngredients.filter((ingredient) => {
      const ingredientLower = ingredient.toLowerCase();

      if (search.length === 1 && isInitialConsonant(search)) {
        return getConsonant(ingredientLower[0]) === search;
      }

      if (search.length <= 2) {
        return ingredientLower.startsWith(searchLower);
      }

      return ingredientLower.includes(searchLower);
    });
  };

  const filteredIngredients = getFilteredIngredients();

  const handleKeyDown = (e) => {
    if (filteredIngredients.length === 0) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setActiveIndex(
          (prevIndex) => (prevIndex + 1) % filteredIngredients.length
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setActiveIndex(
          (prevIndex) =>
            (prevIndex - 1 + filteredIngredients.length) %
            filteredIngredients.length
        );
        break;
      case "Enter":
        e.preventDefault();
        handleIngredientSelect(filteredIngredients[0]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setActiveIndex(0);
  }, [search]);

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 lg:w-2/5 bg-white p-6 md:p-16 relative h-screen overflow-auto">
        <OnboardingHeader
          currentStep={currentStep}
          onboardingValue={onboardingValue}
          setOnboardingValue={setOnboardingValue}
        />
        <h1 className="text-2xl font-semibold text-center mb-2 mt-16 md:mt-0">
          제외할 재료를 선택해 주세요
        </h1>
        <p className="text-sm mb-8 text-gray-500">
          * 먹지 말아야하는 (알레르기가 있는) 음식 혹은 재료
        </p>

        <div className="w-full space-y-3 mb-10">
          {commonIngredients.map((ingredient, index) => (
            <button
              key={index}
              className={`py-2 px-4 border rounded-full me-2 text-sm  ${
                selectedIngredients.includes(ingredient)
                  ? "bg-amber-200 border-amber-200"
                  : "bg-white"
              } text-gray-700`}
              onClick={() => handleIngredientSelect(ingredient)}
            >
              {ingredient}
            </button>
          ))}
        </div>

        <div className="w-full">
          <p className="text-m mb-3 text-gray-700 text-left">그 외 재료 검색</p>
          <input
            ref={searchInputRef}
            type="text"
            className="w-full py-2 px-4 border rounded-full mb-4 text-sm"
            placeholder="재료 검색"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>

        {search && (
          <ul className="w-full border rounded-full mb-6 bg-white">
            {filteredIngredients.map((ingredient, index) => (
              <li
                key={index}
                className={`py-2 px-4 cursor-pointer ${
                  index === activeIndex ? "bg-gray-200" : "hover:bg-gray-100"
                }`}
                onClick={() => handleIngredientSelect(ingredient)}
              >
                {ingredient}
              </li>
            ))}
          </ul>
        )}

        <div className="flex mb-8 container bg-gray-100 py-4 px-4 gap-2 rounded-md flex-wrap">
          {selectedIngredients.map((ingredient, index) => (
            <div
              key={index}
              className="items-start py-2 px-4 rounded-full bg-amber-200 text-xs text-gray-700"
            >
              {ingredient}
              <button
                className="ml-2"
                onClick={() => handleIngredientRemove(ingredient)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/x.png`}
                  alt="아이콘"
                  className="w-2 h-2"
                />
              </button>
            </div>
          ))}
        </div>

        <button
          className="w-full py-2 bg-amber-400 text-gray-700 font-semibold rounded-full shadow-md"
          onClick={handleNextClick}
        >
          {location.state && location.state.fromInputCheck
            ? "변경 완료"
            : "다음으로"}
        </button>
      </div>
    </div>
  );
};

export default ExcludedIngredients;
