import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useStore from "../../commonStore";
import { supbaseWrapper } from "../../supabaseClient";
import { OnboardingHeader } from "../component";

const diets = [
  "일반식",
  "다이어트",
  "채식",
  "근육증가",
  "글루텐프리",
  "저당",
  "저염",
  "저탄수화물",
];

const currentStep = 4;
const EatingHabits = () => {
  const { upsertSurvey } = supbaseWrapper();
  const { onboardingValue, setOnboardingValue } = useStore();
  const [selectedDiets, setSelectedDiets] = useState(
    onboardingValue.eatingHabits || []
  );
  const [otherDiet, setOtherDiet] = useState("");
  const [showOtherInput, setShowOtherInput] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const updateData = useCallback(
    async (params) => {
      await upsertSurvey(params);
    },
    []
  );

  useEffect(() => {
    if (onboardingValue.startIntro !== true) {
      navigate("/");
    } else {
      if (!location.state || location.state.fromInputCheck !== true) {
        let params = {...onboardingValue, lastStep: currentStep}
        updateData(params);
        setOnboardingValue(params);
      }
    }
  }, []);

  const handleDietSelect = (diet) => {
    if (selectedDiets.includes(diet)) {
      setSelectedDiets(selectedDiets.filter((item) => item !== diet));
    } else {
      setSelectedDiets([...selectedDiets, diet]);
    }
  };

  const handleNextClick = () => {
    // if (selectedDiets.length === 0 && otherDiet.trim() === "") {
    //     toast.error("식사 스타일을 선택해 주세요!")
    //     return
    // }

    const finalSelectedDiets = otherDiet
      ? [...selectedDiets, otherDiet]
      : selectedDiets;

    let params = { ...onboardingValue, eatingHabits: finalSelectedDiets };
    updateData(params);
    setOnboardingValue(params);

    if (location.state && location.state.fromInputCheck === true) {
      navigate("/inputCheck");
    } else {
      navigate(`/step${currentStep + 1}`);
    }
  };

  const handleSendFeedback = () => {
    if (otherDiet.trim() === "") {
      toast.error("입력해 주세요.");
      return;
    }
    toast.success("피드백 주셔서 감사합니다.");
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 lg:w-2/5 bg-white p-6 md:p-16 relative h-screen overflow-auto">
        <OnboardingHeader
          currentStep={currentStep}
          onboardingValue={onboardingValue}
          setOnboardingValue={setOnboardingValue}
        />
        <h1 className="text-2xl font-semibold text-center mb-2 mt-16 md:mt-0">
          당신의 식사 스타일은 어떤가요?
        </h1>
        <p className="text-sm mb-8 text-gray-500"> * 중복선택 가능</p>

        {/* 식사 스타일 선택 버튼들 */}
        <div className="w-full space-y-3 mb-10">
          {diets.map((diet, index) => (
            <button
              key={index}
              className={`py-2 px-4 border rounded-full me-2 text-sm  ${
                selectedDiets.includes(diet)
                  ? "bg-amber-200 border-amber-200"
                  : "bg-white"
              } text-gray-700`}
              onClick={() => handleDietSelect(diet)}
            >
              {diet}
            </button>
          ))}
        </div>

        {/* 찾는 스타일 없음 글씨 */}
        <div className="w-full text-right mb-3">
          <button
            className="text-gray-400 underline text-sm"
            onClick={() => setShowOtherInput(!showOtherInput)}
          >
            찾는 스타일 없음
          </button>
          <div className="col-span-2 flex justify-end">
            <p className="text-xs mb-3 text-gray-500">
              피드백 주신다면 추후 추가예정입니다.
            </p>
          </div>
        </div>

        {/* 기타 식사 스타일 입력 */}
        {showOtherInput && (
          <div className="w-full grid grid-cols-8 gap-1 mb-10 items-center">
            <div className="col-span-7">
              <input
                type="text"
                className="w-full py-2 px-4 rounded-full border text-sm text-gray-700"
                value={otherDiet}
                onChange={(e) => setOtherDiet(e.target.value)}
                placeholder="추가 되었으면 하는 스타일"
              />
            </div>
            <img
              className="items-center"
              src={`${process.env.PUBLIC_URL}/static/send.png`}
              style={{ width: 35, cursor: "pointer" }}
              alt="Send feedback"
              onClick={handleSendFeedback}
            />
          </div>
        )}

        <button
          className="w-full py-2 bg-amber-400 text-gray-700 font-semibold rounded-full shadow-md"
          onClick={handleNextClick}
        >
          {location.state && location.state.fromInputCheck
            ? "변경 완료"
            : "다음으로"}
        </button>
      </div>
    </div>
  );
};

export default EatingHabits;
