import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useStore from "../../commonStore";
import { supbaseWrapper } from "../../supabaseClient";
import { OnboardingHeader } from "../component";

const areas = [
  "강남",
  "강동",
  "강북",
  "강서",
  "관악",
  "광진",
  "구로",
  "금천",
  "노원",
  "도봉",
  "동대문",
  "동작",
  "마포",
  "서대문",
  "서초",
  "성동",
  "성북",
  "송파",
  "양천",
  "영등포",
  "용산",
  "은평",
  "종로",
  "중랑",
  "의정부",
  "동두천",
  "신철원",
  "신림",
  "낙성대"
];

const currentStep = 1;
const Area = () => {
  const { upsertSurvey } = supbaseWrapper();
  const { onboardingValue, setOnboardingValue } = useStore();
  const [selectedAreas, setSelectedAreas] = useState(
    onboardingValue.area || []
  );
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const updateData = useCallback(
    async (params) => {
      await upsertSurvey(params);
    },
    [onboardingValue.id]
  );

  useEffect(() => {
    if (onboardingValue.startIntro !== true) {
      navigate("/");
    } else {
      if (!location.state || location.state.fromInputCheck !== true) {
        updateData({ ...onboardingValue, lastStep: "area" });
      }
    }
  }, [onboardingValue, location.state, navigate, updateData]);

  const handleAreaSelect = (area) => {
    if (!selectedAreas.includes(area)) {
      setSelectedAreas([...selectedAreas, area]);
    }
    setSearch("");
  };

  const handleAreaRemove = (area) => {
    setSelectedAreas(selectedAreas.filter((loc) => loc !== area));
  };

  const handleNextClick = () => {
    if (selectedAreas.length === 0) {
      toast.error("식사를 원하시는 지역을 선택해 주세요!");
      return;
    }

    let params = { ...onboardingValue, area: selectedAreas }
    updateData(params);
    setOnboardingValue(params);

    if (location.state && location.state.fromInputCheck === true) {
      navigate("/inputCheck");
    } else {
      navigate(`/step${currentStep + 1}`);
    }
  };

  const getFilteredAreas = () => {
    if (!search) return [];
    const searchLower = search.toLowerCase();
    return areas.filter((area) => area.toLowerCase().includes(searchLower));
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const filteredAreas = getFilteredAreas();
      if (filteredAreas.length > 0) {
        handleAreaSelect(filteredAreas[0]);
      } else {
        handleAreaSelect(search);
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 lg:w-2/5 bg-white p-6 md:p-16 relative h-screen overflow-auto">
        <OnboardingHeader
          currentStep={currentStep}
          onboardingValue={onboardingValue}
          setOnboardingValue={setOnboardingValue}
        />
        <h1 className="text-2xl font-semibold text-gray-700 text-center mb-2 mt-16 md:mt-0">
          주로 외식하는 장소를 선택하세요
        </h1>
        <p className="text-sm mb-8 text-gray-500">
          * 직장 혹은 집(밖에서 식사하는 장소)
        </p>

        <input
          type="text"
          className="w-full py-2 px-4 border rounded-full text-sm"
          placeholder="지역 검색"
          value={search}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
          maxLength={7}
        />

        {search && (
          <ul className="w-full border rounded-md mb-6 bg-white divide-y divide-gray-200">
            {getFilteredAreas().map((area, index) => (
              <li
                key={index}
                className="py-2 px-4 cursor-pointer hover:bg-gray-100 text-sm flex items-center justify-center"
                onClick={() => handleAreaSelect(area)}
              >
                {area}
              </li>
            ))}
            {getFilteredAreas().length === 0 && (
              <li
                className="py-2 px-4 cursor-pointer hover:bg-gray-100 text-sm flex items-center justify-center"
                onClick={() => handleAreaSelect(search)}
              >
                {search}
              </li>
            )}
          </ul>
        )}

        {/* 선택된 장소 표시 */}
        {selectedAreas.map((loc, index) => (
          <div
            key={index}
            className="w-full py-2 px-5 rounded-full bg-amber-100 text-gray-700 mt-3 text-sm  flex justify-between items-center"
          >
            {loc}
            <button onClick={() => handleAreaRemove(loc)}>
              <img
                src={`${process.env.PUBLIC_URL}/static/x.png`}
                alt="아이콘"
                className="w-3 h-3"
              />
            </button>
          </div>
        ))}

        <button
          className="w-full py-2 bg-amber-400 mt-10 text-gray-700 font-semibold rounded-full shadow-md"
          onClick={handleNextClick}
        >
          {location.state && location.state.fromInputCheck
            ? "변경 완료"
            : "다음으로"}
        </button>
      </div>
    </div>
  );
};

export default Area;
