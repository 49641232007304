import axios from 'axios'
import useStore from './commonStore'

const apiClient = axios.create({ timeout: 15000, headers: { 'Content-Type': 'application/json' } })

const useAxiosWrapper = () => {
    const { setLoading, apiRunning, setApiRunning } = useStore()
    const token = localStorage.getItem('token')

    const api = async (options) => {
        if (options?.loading != false) {
            setLoading(true)
        }

        // await 관련 버그 있는 것 같음
        // if (apiRunning == true) {
        //     return
        // }
        
        setApiRunning(true)
        try {
            console.log(options.url, ' 호출!')
            const response = await apiClient({ headers: { Authorization: `Bearer ${token}` }, method: 'GET', ...options })
            console.log('response data : ', response.data)
            return response
        } catch (err) {
            throw err
        } finally {
            setLoading(false)
            setApiRunning(false)
        }
    }

    return { api }
}

export { useAxiosWrapper }
