import React, { useState, useEffect } from "react";
import { useAxiosWrapper } from "../common";
import useStore from "../commonStore";
import { SearchBar } from "./component";
import { useNavigate } from "react-router-dom";
import { Header } from "./component";

const RecipeResultList = () => {
  const { api } = useAxiosWrapper();
  const navigate = useNavigate();
  const { searchText, setSearchText } = useStore();
  const [recipeList, setRecipeList] = useState([]);
  const { isLogin, setIsLogin } = useStore();

  const getRecipeList = async () => {
    try {
      const response = await api({
        method: "POST",
        url: "/api/recipe/list/search",
        data: { searchText: searchText },
      });
      setRecipeList(response.data || []);
    } catch (error) {
      console.error("Error fetching question list:", error);
    }
  };

  const moveDetail = (id) => {
    navigate(`/recipeDetail/${id}`);
  };

  useEffect(() => {
    if (searchText) {
      getRecipeList(); // searchText가 있을 때만 검색 수행
    }
  }, [searchText]);

  const emailLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    setIsLogin(false);
    navigate("/");
  };

  if (!Array.isArray(recipeList) || recipeList.length < 1) {
    return (
      <div className="flex flex-col md:flex-row h-screen bg-gray-100">
        <div className="flex flex-col items-center  px-3 justify-center w-full md:w-1/2 lg:w-2/5 bg-white  relative h-screen overflow-auto">
          <Header />
          <h1 className="text-xl text-gray-700 font-semibold mb-7">
            레시피 검색
          </h1>
          <SearchBar />
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col md:flex-row h-screen bg-gray-100">
        <div className="flex flex-col items-center pt-20 px-3 justify-center w-full md:w-1/2 lg:w-2/5 bg-white  relative h-screen overflow-auto">
          <div className="absolute top-2 left-0 right-0 flex justify-between p-4">
            <button
              className="text-gray-500 flex items-center text-sm mb:text-sm"
              onClick={() => navigate(`/`)}
            >
              <img
                src={`${process.env.PUBLIC_URL}/static/back.png`}
                alt="아이콘"
                className="w-3 h-5 mr-2"
              />
              처음으로
            </button>
            {isLogin ? (
              <button
                className="flex items-center text-sm text-xs  mb:text-sm text-gray-500"
                onClick={emailLogout} // 로그아웃 함수 호출
              >
                로그아웃
                <img
                  src={`${process.env.PUBLIC_URL}/static/sign_out.png`} // 로그아웃 아이콘으로 변경
                  alt="아이콘"
                  className="w-6 ml-1"
                />
              </button>
            ) : null}
          </div>
          <div className="flex flex-col items-center h-screen py-10 ">
            <SearchBar data={{ setRecipeList }} />
            <div className="grid gap-5 w-full">
              {recipeList.map((item) => (
                <div
                  className="flex flex-col items-center border border-gray-300 rounded-lg bg-white shadow-md overflow-hidden cursor-pointer"
                  key={item.doc_id}
                  onClick={() => moveDetail(item.doc_id)}
                >
                  <div className="w-full aspect-w-3 h-3/4 overflow-hidden">
                    <img
                      className="w-full h-full object-cover "
                      src={item.imgUrl}
                      alt=""
                      onError={(e) => (e.target.src = "./static/logo.gif")}
                    />
                  </div>
                  <div className="p-2 m-2 w-full text-left h-16">
                    <div className="font-serif mb-3 text-xs font-bold overflow-hidden text-ellipsis line-clamp-2 text-gray-700">
                      {item.title}
                    </div>
                    <div className="font-serif text-xs text-right text-gray-500">
                      by. {item.author}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default RecipeResultList;
