import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supbaseWrapper } from "../../supabaseClient";
import useStore from "../../commonStore";
import { Header } from "../component";

const Intro = () => {
  const {
    onboardingValue,
    setOnboardingValue,
    setCommonMealList,
    setCommonMealDetailObj,
  } = useStore();
  const navigate = useNavigate();
  const { isLogin, setIsLogin } = useStore();
  const { upsertSurvey } = supbaseWrapper();

  const insertData = async () => {
    const data = await upsertSurvey({ ...onboardingValue, lastStep: "intro" });

    let id = -1;
    if (data.lastInsertId) {
      id = data.lastInsertId;
    }

    if (onboardingValue.id !== id) {
      setOnboardingValue({ id, startIntro: true });
    }
    setCommonMealList([]);
    setCommonMealDetailObj({});
  };

  useEffect(() => {
    insertData();
  }, []);

  const handleNextClick = () => {
    navigate("/step1");
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 lg:w-2/5 bg-white p-6 md:p-8 relative h-screen overflow-auto">
        <Header showLogo={false} />

        {/* 후킹 문구 */}
        <div className="text-lg font-semibold text-center mb-6 text-gray-700 leading-7">
          <p>
            취향과 건강을 생각한 맞춤형 식단
            <br />
            당신의 한주식단을 책임질게요
          </p>
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/static/omjm_logo.png`}
          alt="아이콘"
          className="w-48 md:w-60 p-2"
        />

        <button
          className="w-full py-2 bg-amber-400 text-gray-700 rounded-3xl mt-6 font-bold shadow-md"
          onClick={handleNextClick}
        >
          식단 생성 하기
        </button>
      </div>
    </div>
  );
};

export default Intro;
