import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useStore from "../../commonStore";
import { supbaseWrapper } from "../../supabaseClient";
import { OnboardingHeader } from "../component";

const conditions = [
  "당뇨",
  "통풍",
  "고혈압",
  "비만",
  "고지혈증",
  "심장질환",
  "갑상선",
  "간질환",
];

const currentStep = 3;
const Health = () => {
  const { upsertSurvey } = supbaseWrapper();
  const { onboardingValue, setOnboardingValue } = useStore();
  const [selectedConditions, setSelectedConditions] = useState(
    onboardingValue.health || []
  );

  const [selectedOtherConditions, setSelectedOtherConditions] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const updateData = useCallback(async (params) => {
    await upsertSurvey(params)
  }, [])

  useEffect(() => {
    if (onboardingValue.startIntro !== true) {
      navigate("/");
    } else {
      if (!location.state || location.state.fromInputCheck !== true) {
        let params = {...onboardingValue, lastStep: currentStep}
        updateData(params);
        setOnboardingValue(params);
      }
    }
  }, []);

  const handleConditionSelect = (condition) => {
    if (selectedConditions.includes(condition)) {
      setSelectedConditions(
        selectedConditions.filter((item) => item !== condition)
      );
    } else {
      setSelectedConditions([...selectedConditions, condition]);
    }
  };

  const handleOtherConditionSelect = (e) => {
    const condition = e.target.value;
    if (condition && !selectedConditions.includes(condition)) {
      setSelectedConditions([...selectedConditions, condition]);
    }
    setSelectedOtherConditions("");
  };

  const handleConditionRemove = (condition) => {
    setSelectedConditions(
      selectedConditions.filter((item) => item !== condition)
    );
  };

  const handleNextClick = () => {
    let values = { ...onboardingValue, health: selectedConditions }
    updateData(values);
    setOnboardingValue(values);

    if (location.state && location.state.fromInputCheck === true) {
      navigate("/inputCheck");
    } else {
      navigate(`/step${currentStep + 1}`);
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 lg:w-2/5 bg-white p-6 md:p-16 relative h-screen overflow-auto">
        <OnboardingHeader
          currentStep={currentStep}
          onboardingValue={onboardingValue}
          setOnboardingValue={setOnboardingValue}
        />
        <h1 className="text-2xl font-semibold text-gray-700 text-center mb-2 mt-16 md:mt-0">
          건강 상태를 선택해 주세요
        </h1>
        <p className="text-sm mb-8 text-gray-500"> * 선택은 필수가 아닙니다.</p>

        {/* 조건 선택 버튼들 */}
        <div className="w-full space-y-3 mb-10">
          {conditions.map((condition, index) => (
            <button
              key={index}
              className={`py-2 px-4 border rounded-full me-2 text-sm ${
                selectedConditions.includes(condition)
                  ? "bg-amber-200 border-amber-200"
                  : "bg-white"
              } text-gray-700`}
              onClick={() => handleConditionSelect(condition)}
            >
              {condition}
            </button>
          ))}
        </div>

        {/* 선택된 조건들 */}
        <div className="flex mb-8 container bg-gray-100 py-4 px-4 gap-2 rounded-md flex-wrap">
          {selectedConditions.map((condition, index) => (
            <div
              key={index}
              className="items-start py-2 px-4 rounded-full bg-amber-200 text-xs text-gray-700"
            >
              {condition}
              <button
                className="ml-2"
                onClick={() => handleConditionRemove(condition)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/x.png`}
                  alt="아이콘"
                  className="w-2 h-2"
                />
              </button>
            </div>
          ))}
        </div>

        <button
          className="w-full py-2 bg-amber-400 text-gray-700 font-semibold rounded-full shadow-md"
          onClick={handleNextClick}
        >
          {location.state && location.state.fromInputCheck
            ? "변경 완료"
            : "다음으로"}
        </button>
      </div>
    </div>
  );
};

export default Health;
