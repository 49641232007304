import React, { useEffect, useState, useCallback } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { supbaseWrapper } from "../../supabaseClient";
import useStore from "../../commonStore";
import { toast } from "react-toastify";

export const useProductData = () => {
  const location = useLocation();
  const { id } = location.state || {};
  const [productDetail, setProductDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentId, setCurrentId] = useState(null); // 추가: 현재 ID 상태
  const { getProductDetail } = supbaseWrapper();

  const fetchProductDetail = useCallback(
    async (id) => {
      console.log("Fetching product detail for ID:", id); // 디버깅 로그 추가
      if (!id) {
        setError("Invalid product ID");
        setIsLoading(false);
        return;
      }

      try {
        const res = await getProductDetail(id);
        if (res.error) {
          throw new Error(res.error.message);
        }
        setProductDetail(res.data);
      } catch (e) {
        console.error("Error fetching product details: ", e);
        setError(e.message);
      } finally {
        setIsLoading(false);
      }
    },
    [id, getProductDetail]
  );

  return { productDetail, isLoading, error, fetchProductDetail };
};
export const ProductHeader = ({ productDetail }) => (
  <div className="w-full flex flex-col items-center">
    <div className="w-full rounded-xl overflow-hidden">
      <img
        src={productDetail.product_img_url || "default_image_url"}
        alt={productDetail.product_name}
        className="w-full h-full object-cover"
      />
    </div>
    <div className="w-full text-left mt-4">
      <div className="text-gray-700 text-lg md:text-xl font-bold">
        {productDetail.product_name}
      </div>
      <div className="text-amber-500 text-lg font-bold">
        {productDetail.price
          ? `${productDetail.price.toLocaleString()}원`
          : "가격 정보 없음"}
      </div>
    </div>
  </div>
);

export const NutrientInfo = ({ nutrientInfo }) => {
  if (!nutrientInfo || Object.keys(nutrientInfo).length === 0) return null;

  const nutrients = [
    {
      label: "칼로리",
      value: nutrientInfo.calorie ? `${nutrientInfo.calorie} kcal` : null,
    },
    {
      label: "탄수화물",
      value: nutrientInfo.carb ? `${nutrientInfo.carb} g` : null,
    },
    {
      label: " ㄴ식이섬유",
      value: nutrientInfo.fiber ? `${nutrientInfo.fiber} g` : null,
    },
    {
      label: " ㄴ설탕당",
      value: nutrientInfo.sugar ? `${nutrientInfo.sugar} g` : null,
    },
    {
      label: "단백질",
      value: nutrientInfo.protein ? `${nutrientInfo.protein} g` : null,
    },
    { label: "지방", value: nutrientInfo.fat ? `${nutrientInfo.fat} g` : null },
    {
      label: " ㄴ포화지방",
      value: nutrientInfo.saturated_fat
        ? `${nutrientInfo.saturated_fat} g`
        : null,
    },
    {
      label: " ㄴ불포화지방",
      value: nutrientInfo.unsaturated_fat
        ? `${nutrientInfo.unsaturated_fat} g`
        : null,
    },
    {
      label: " ㄴ트랜스지방",
      value: nutrientInfo.trans_fat ? `${nutrientInfo.trans_fat} g` : null,
    },
    {
      label: "콜레스테롤",
      value: nutrientInfo.cholesterol ? `${nutrientInfo.cholesterol} mg` : null,
    },
    {
      label: "나트륨",
      value: nutrientInfo.potassium ? `${nutrientInfo.sodium} mg` : null,
    },
    {
      label: "나트륨",
      value: nutrientInfo.sodium ? `${nutrientInfo.potassium} mg` : null,
    },
  ].filter(
    (item) =>
      item.value && item.value.trim() !== "" && parseFloat(item.value) !== 0
  );

  return (
    <div className="w-full flex flex-col items-center gap-5">
      <div className="w-full flex justify-between items-start border-b border-zinc-500 pb-2">
        <span className="text-neutral-700 text-base md:text-lg font-bold">
          영양성분
        </span>
        <span className="text-neutral-700 text-xs md:text-sm">
          {nutrientInfo.serving_size}인분
        </span>
      </div>
      <div className="w-full flex flex-col gap-5">
        {nutrients.map((item, index) => (
          <div
            key={index}
            className={`w-full flex justify-start items-center border-b border-zinc-100 pb-1 ${
              item.label.startsWith(" ㄴ") ? "pl-4" : ""
            }`}
          >
            <span className="text-gray-700 text-xs md:text-sm w-1/2">
              {item.label}
            </span>
            <span className="text-gray-700 text-xs md:text-sm w-1/2 text-left">
              {item.value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export const RestaurantInfo = ({ productDetail }) => (
  <div className="w-full flex items-center md:gap-5">
    <div className="w-2/5 rounded-xl overflow-hidden">
      <img
        src={
          productDetail.brand_img_url ||
          "https://techcrunch.com/wp-content/uploads/2015/08/shutterstock_237882754.jpg"
        }
        alt={productDetail.brand_name}
        className="w-full h-full object-cover"
      />
    </div>
    <div className="flex flex-col gap-2">
      <div className="text-gray-700 text-md font-bold md:text-lg">
        {productDetail.center?.name}
      </div>
      <div className="text-zinc-500 text-xs md:text-sm">
        <div className="flex items-center gap-1">
          <img
            src={`${process.env.PUBLIC_URL}/static/location.png`}
            alt="아이콘"
            className="w-5 h-5"
          />
          <span>{productDetail.center?.address}</span>
        </div>
      </div>
      <div className="text-zinc-500 text-xs md:text-sm">
        <div className="flex items-center gap-1">
          <img
            src={`${process.env.PUBLIC_URL}/static/phone.png`}
            alt="아이콘"
            className="w-5 h-5"
          />
          <span>{productDetail.center?.tel}</span>
        </div>
      </div>
    </div>
  </div>
);

export const ProductInfo = ({ productDetail }) => (
  <div className="w-full flex items-center gap-2 md:gap-3">
    <div className="w-2/5 rounded-xl overflow-hidden">
      <img
        src={
          productDetail.brand_img_url ||
          "https://techcrunch.com/wp-content/uploads/2015/08/shutterstock_237882754.jpg"
        }
        alt={productDetail.brand_name}
        className="w-full h-full object-cover"
      />
    </div>
    <div className="w-3/5 flex flex-col gap-4">
      <div className="text-gray-700 text-base md:text-lg font-bold ">
        {productDetail.brand_name}의 {productDetail.product_name}
      </div>
      <button
        className="w-full py-2  bg-amber-400 text-gray-700 font-semibold rounded-full shadow-md"
        onClick={() => (window.location.href = productDetail.product_url)}
      >
        구매하러 가기
      </button>
    </div>
  </div>
);

export const OtherMenus = ({ brandName, relatedProducts }) => {
  const navigate = useNavigate();

  const handleProductClick = (id) => {
    navigate(`/productDetail`, { state: { id: id } });
  };
  return (
    <div className="w-full flex flex-col gap-3">
      <div className="text-left">
        <span className="text-amber-500 text-lg md:text-xl font-bold">
          {brandName}
        </span>
        <span className="text-gray-700 text-lg font-bold">의 다른 메뉴들</span>
      </div>
      <div className="flex flex-col w-full gap-5">
        {relatedProducts.map((product) => (
          <div
            key={product.id}
            onClick={() => handleProductClick(product.id)}
            className="m-1 bg-white p-2 rounded-lg shadow-lg flex gap-4 z-10"
          >
            <div className="w-2/5 aspect-w-16 aspect-h-5">
              <img
                src={product.image}
                alt={product.name}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div className="flex flex-col w-3/5 justify-center flex-grow gap-2">
              <div className="text-gray-700 text-sm md:text-base font-bold">
                {product.name}
              </div>
              <div className="text-amber-500 text-xs mb:text-sm font-bold">
                {product.price.toLocaleString()} 원
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
