import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useStore from '../../commonStore'
import { supbaseWrapper } from '../../supabaseClient'
import { OnboardingHeader } from '../component'

const mealOptions = [
    {
        mealTime: '아침',
        options: ['공복', '요리레시피', '간편레시피', '밀키트', '식당', '편의점', '도시락'],
    },
    {
        mealTime: '점심',
        options: ['공복', '요리레시피', '간편레시피', '밀키트', '식당', '편의점', '도시락'],
    },
    {
        mealTime: '저녁',
        options: ['공복', '요리레시피', '간편레시피', '밀키트', '식당', '편의점', '도시락'],
    },
]
const currentStep = 1
const MealCategory = () => {
    const { upsertSurvey } = supbaseWrapper()
    const { onboardingValue, setOnboardingValue } = useStore()
    const [meals, setMeals] = useState(onboardingValue.mealCategory || ['', '', ''])

    const [isNarrow, setIsNarrow] = useState(window.innerWidth < 310)
    const [isVeryNarrow, setIsVeryNarrow] = useState(window.innerWidth < 257)
    const navigate = useNavigate()
    const location = useLocation()

    const updateData = useCallback(async (params) => {
        await upsertSurvey(params)
    }, [])

    useEffect(() => {
        if (onboardingValue.startIntro !== true) {
            navigate('/')
        } else {
            if (!location.state || location.state.fromInputCheck !== true) {
                let params = { ...onboardingValue, lastStep: currentStep }
                updateData(params)
                setOnboardingValue(params)
            }
        }
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setIsNarrow(window.innerWidth < 310)
            setIsVeryNarrow(window.innerWidth < 257)
        }

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const handleMealClick = (mealTime, option) => {
        const index = ['아침', '점심', '저녁'].indexOf(mealTime)
        const newMeals = [...meals]
        newMeals[index] = option
        setMeals(newMeals)
    }

    const handleNextClick = () => {
        if (meals.includes('')) {
            toast.error('모든 식사 방법을 선택해 주세요!')
            return
        }
        let values = { ...onboardingValue, mealCategory: meals }
        updateData(values)
        setOnboardingValue(values)

        if (meals.includes('식당')) {
            navigate('/area')
        } else if (location.state && location.state.fromInputCheck === true) {
            navigate('/inputCheck')
        } else {
            navigate(`/step${currentStep + 1}`)
        }
    }

    return (
        <div className="flex flex-col md:flex-row h-screen bg-gray-100">
            <div className="flex flex-col w-full md:w-1/2 lg:w-2/5 bg-white p-3 md:p-16 relative h-screen">
                <OnboardingHeader
                    currentStep={currentStep}
                    onboardingValue={onboardingValue}
                    setOnboardingValue={setOnboardingValue}
                />
                <div
                    id="mc-area-1"
                    className={`flex flex-col items-center justify-center h-full overflow-auto ${
                        isVeryNarrow ? 'pt-32' : isNarrow ? 'pt-24' : 'pt-10'
                    }`}
                >
                    <h1 className="text-2xl font-semibold text-gray-700 text-center mb-10 mt-16 md:mt-0">
                        하루 식사 루틴은 어떻게 되시나요?
                    </h1>

                    <div className="w-full space-y-8 mb-6">
                        {mealOptions.map((meal, mealIndex) => (
                            <div key={meal.mealTime}>
                                <h2 className="text-lg font-semibold text-gray-700 mb-2">{meal.mealTime}</h2>
                                <div className="flex flex-wrap gap-2">
                                    {meal.options.map((option, index) => (
                                        <button
                                            key={index}
                                            className={`py-2 px-4 border text-sm rounded-full ${
                                                meals[mealIndex] === option
                                                    ? 'bg-amber-200 border-amber-200'
                                                    : 'bg-white'
                                            } text-gray-700`}
                                            onClick={() => handleMealClick(meal.mealTime, option)}
                                        >
                                            {option}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>

                    <button
                        className="w-full py-2 bg-amber-400 text-gray-700 mt-6 font-semibold rounded-full shadow-md"
                        onClick={handleNextClick}
                    >
                        {location.state && location.state.fromInputCheck ? '변경 완료' : '다음으로'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MealCategory
