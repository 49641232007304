import { createClient } from "@supabase/supabase-js";
import { useAxiosWrapper } from "./common";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const esUrl = process.env.REACT_APP_ES_SEARCH_URL;
let supabase = null;
if (supabaseUrl && supabaseKey) {
  supabase = createClient(supabaseUrl, supabaseKey);
}

const supbaseWrapper = () => {
  const { api } = useAxiosWrapper();

  const insertSurvey = async (params) => {
    if (!supabase) {
      console.log("supabase deactive");
    } else {
      const { data, error } = await supabase
        .from("survey_1")
        .insert(params)
        .select();
      if (error) {
        console.error("insertSurvey error : ", error);
      }
      return data;
    }
  };
  const updateSurvey = async (id, data) => {
    if (!supabase) {
      console.log("supabase deactive");
    } else {
      const { error } = await supabase
        .from("survey_1")
        .update(data)
        .eq("id", id);
      if (error) {
        console.error("updateSurvey error : ", error);
      }
      return error;
    }
  };
  const createPlan = async (params) => {
    if (!esUrl) {
      console.log("REACT_APP_ES_SEARCH_URL is none");
    } else {
      try {
        console.log("params:", params);
        const response = await api({
          method: "POST",
          url: `${esUrl}/create-meal-plan`,
          data: params,
          headers: {},
        });
        return response;
      } catch (error) {
        console.error("Error during elasticSearch request:", error);
        throw new Error("ElasticSearch request failed");
      }
    }
  };

  const getRecipeDetail = async (id) => {
    const response = await api({
      method: "POST",
      url: `${esUrl}/recipe-detail`,
      data: { id: id },
    });
    return response;
  };

  const createRecipeTips = async (itemId, surveyId) => {
    const response = await api({
      method: "POST",
      url: `${esUrl}/recipe-tips-py`,
      data: { itemId: itemId, surveyId: surveyId },
    });
    return response;
  };

  const getProductDetail = async (id) => {
    const response = await fetch(`${esUrl}/product-detail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      throw new Error(`Error fetching product details: ${response.statusText}`);
    }

    const data = await response.json();
    return { data };
  };

  const getMealPlan = async (surveyId) => {
    if (!esUrl) {
      console.log("REACT_APP_ES_SEARCH_URL is none");
    } else {
      const response = await api({
        method: "POST",
        url: `${esUrl}/get-meal-plan`,
        data: {
          surveyId: surveyId,
        },
        headers: {},
      });
      return response;
    }
  };

  const changeMeal = async (dayMealId, mealType, category) => {
    console.log({ dayMealId, mealType, category });
    if (!esUrl) {
      console.log("REACT_APP_ES_SEARCH_URL is none");
    } else {
      const response = await api({
        method: "POST",
        url: `${esUrl}/change-meal`,
        data: { dayMealId, mealType, category },
        headers: {},
      });
      return response?.data;
    }
  };

  const upsertSurvey = async (data) => {
    if (!esUrl) {
      console.log("REACT_APP_ES_SEARCH_URL is none");
    } else {
      try {
        const response = await api({
          method: "POST",
          url: `${esUrl}/upsert-survey`,
          data: data,
          headers: {},
        });
        console.log("Upsert survey response:", response); // 추가 로그
        return response?.data;
      } catch (error) {
        console.error("Error during upsertSurvey request:", error);
        throw error;
      }
    }
  };

  return {
    supabase,
    insertSurvey,
    updateSurvey,
    createPlan,
    getRecipeDetail,
    getMealPlan,
    getProductDetail,
    changeMeal,
    upsertSurvey,
    createRecipeTips,
  };
};

export { supbaseWrapper };
