// WeeklyMeals.js
import React, { useRef } from "react";
import { useMealData, KakaoShareButton, WeeklyMealItem } from "./mealComponent";
import { Header, Loading } from "../component";
import { useNavigate, useParams } from "react-router-dom";

const WeeklyMeals = () => {
  const { surveyId } = useParams();
  const { data, handleMealClick, scrollAreaRef } = useMealData(surveyId, true);
  const navigate = useNavigate();

  if (!Array.isArray(data) || data.length < 1) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 lg:w-2/5 bg-white px-2 pb-14 relative h-screen overflow-y-auto overflow-x-hidden">
        <Header showBackButton={true} />
        <div
          ref={scrollAreaRef}
          className="flex flex-col w-full overflow-y-auto overflow-x-hidden"
        >
          <h1 className="text-center text-xl font-semibold text-gray-700 mb-5 mt-20">
            일주일 식단 계획
          </h1>
          {data.map((dayData, index) => (
            <div
              key={index}
              className="w-full mb-10 flex flex-col items-center"
            >
              <div className="w-full text-left font-semibold mb-2 ml-4 flex items-center">
                <div className="w-2.5 h-2.5 bg-yellow-500 rounded-full mr-1"></div>
                {dayData.date}
              </div>
              <div className="grid grid-cols-3 gap-1.5 w-full">
                {["아침", "점심", "저녁"].map((mealType, i) => {
                  let mealData = dayData.meals.find(
                    (meal) => meal.mealType == mealType
                  );
                  return (
                    <WeeklyMealItem
                      key={i}
                      meal={mealData}
                      mealType={mealType}
                      dayMealId={dayData.dayMealId}
                      onClick={(id, dataType) =>
                        handleMealClick(id, dataType, surveyId)
                      }
                      surveyId={surveyId}
                    />
                  );
                })}
              </div>
            </div>
          ))}
          <button
            className="w-full py-2 mt-6 mb-14 bg-amber-400 text-gray-700 font-semibold rounded-full shadow-md"
            onClick={() => navigate(`/dailyMeals/${surveyId}`)}
          >
            당일 식단 보러가기
          </button>
          <div className="absolute bottom-0 flex justify-between items-center w-full px-4 bg-white py-3">
            <KakaoShareButton />
            <div className="flex items-center space-x-3">
              <button className="w-8 h-8">
                <img
                  src={`${process.env.PUBLIC_URL}/static/capture.png`}
                  alt="캡처"
                  className="w-full h-full object-contain"
                />
              </button>
              <button className="w-8 h-8">
                <img
                  src={`${process.env.PUBLIC_URL}/static/pdf.png`}
                  alt="PDF"
                  className="w-full h-full object-contain"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeeklyMeals;
