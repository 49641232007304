import React, { useState, useEffect } from "react";
import { supbaseWrapper } from "../../supabaseClient";
import ReactMarkdown from "react-markdown";

const TypewriterEffect = ({ text, components }) => {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 50); //타이핑 속도 조절(밀리초단위)
      return () => clearTimeout(timer);
    }
  }, [currentIndex, text]);

  return <ReactMarkdown components={components}>{displayText}</ReactMarkdown>;
};

const RecipeTips = ({ itemId, surveyId }) => {
  const { createRecipeTips } = supbaseWrapper();
  const [recipeTips, setRecipeTips] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTips = async () => {
      try {
        setLoading(true);
        const response = await createRecipeTips(itemId, surveyId);
        setRecipeTips(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching tips:", err);
        setError(err.message || "팁을 불러오는 데 실패했습니다.");
      } finally {
        setLoading(false);
      }
    };
    fetchTips();
  }, [itemId, surveyId]);

  if (!itemId || !surveyId) {
    return (
      <div className="text-sm font-semibold text-gray-800 p-2">
        필요한 정보가 누락되었습니다.
      </div>
    );
  }

  const markdownComponents = {
    h3: ({ node, ...props }) => (
      <h3 className="text-xs font-semibold mt-4 mb-1" {...props} />
    ),
    h4: ({ node, ...props }) => (
      <h4 className="text-xs font-medium mt-3 mb-1" {...props} />
    ),
    p: ({ node, ...props }) => <p className="mb-2 text-xs" {...props} />,
    ul: ({ node, ...props }) => (
      <ul className="list-disc pl-5 mb-2 text-xs" {...props} />
    ),
    li: ({ node, ...props }) => <li className="mb-1 text-xs" {...props} />,
    strong: ({ node, ...props }) => (
      <strong className="font-semibold text-xs" {...props} />
    ),
  };

  return (
    <div className="p-2">
      <h2 className="text-sm font-semibold text-custom-red mb-2">
        🤖 당뇨 환자 맞춤 팁 !
      </h2>
      {loading ? (
        <div className="text-sm font-semibold text-gray-800">
          <TypewriterEffect text="팁 생성 중..." />
        </div>
      ) : error ? (
        <div className="text-sm font-semibold text-gray-800">
          팁 생성 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.
        </div>
      ) : recipeTips ? (
        <TypewriterEffect
          text={recipeTips.guide}
          components={markdownComponents}
        />
      ) : null}
    </div>
  );
};

export default RecipeTips;
