import React, { useState } from "react";
import { useAxiosWrapper } from "../../common";
import { useNavigate } from "react-router-dom";
import useStore from "../../commonStore";

const SignUp = () => {
  const navigate = useNavigate();
  const { api } = useAxiosWrapper();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
  });

  const emailSignUp = async (e) => {
    e.preventDefault();
    try {
      if (email == "" || !email) {
        setErrorMessage({ email: "이메일을 입력해주세요." });
        return;
      }
      if (password == "" || !password) {
        setErrorMessage({ password: "비밀번호를 입력해주세요." });
        return;
      }
      if (confirmPassword == "" || !confirmPassword) {
        setErrorMessage({ confirmPassword: "확인 비밀번호를 입력해주세요." });
        return;
      }
      if (name == "" || !name) {
        setErrorMessage({ name: "이름을 입력해주세요." });
        return;
      }
      if (password !== confirmPassword) {
        setErrorMessage({ confirmPassword: "비밀번호가 일치하지 않습니다." });
        return;
      }

      const response = await api({
        method: "POST",
        url: "api/user/register",
        data: { email, password, name },
      });

      window.alert("환영합니다! 가입하신 아이디로 로그인 해주세요.");
      navigate(`/login`);
    } catch (error) {
      window.alert("오류. 다시 시도해 주세요.");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      emailSignUp(e);
    }
  };

  const showErrorMessage = (type) => {
    return errorMessage[type] || "";
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 lg:w-2/5 bg-white p-6 md:p-16 relative h-screen overflow-auto">
        <div className="absolute top-2 left-0 right-0 flex justify-start p-4">
          <button
            className="text-gray-500 flex items-center text-sm"
            onClick={() => navigate(-1)}
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/back.png`}
              alt="아이콘"
              className="w-3 h-5 mr-2"
            />
          </button>
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/static/omjm_logo.png`}
          alt="아이콘"
          className="w-48 md:w-60 p-2"
        />
        <form className="w-full mt-4">
          <input
            className="w-full px-4 py-2 mb-3 text-sm bg-white rounded-[30px] border border-neutral-200 focus:outline-none focus:border-gray-400"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorMessage((prev) => ({ ...prev, email: "" }));
            }}
            placeholder="이메일 입력"
            onKeyDown={handleKeyDown}
            autoComplete="email"
          />
          {showErrorMessage("email") && (
            <div className="error">{showErrorMessage("email")}</div>
          )}

          <input
            className="w-full px-4 py-2 mb-3 text-sm bg-white rounded-[30px] border border-neutral-200 focus:outline-none focus:border-gray-400"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorMessage((prev) => ({ ...prev, password: "" }));
            }}
            placeholder="비밀번호 입력"
            onKeyDown={handleKeyDown}
            autoComplete="current-password"
          />
          {showErrorMessage("password") && (
            <div className="error">{showErrorMessage("password")}</div>
          )}

          <input
            className="w-full px-4 py-2 mb-3 text-sm bg-white rounded-[30px] border border-neutral-200 focus:outline-none focus:border-gray-400"
            type="password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setErrorMessage((prev) => ({ ...prev, confirmPassword: "" }));
            }}
            placeholder="비밀번호 확인"
            autoComplete="current-password"
            onKeyDown={handleKeyDown}
          />
          {showErrorMessage("confirmPassword") && (
            <div className="error">{showErrorMessage("confirmPassword")}</div>
          )}
          <input
            className="w-full px-4 py-2 mb-3 text-sm bg-white rounded-[30px] border border-neutral-200 focus:outline-none focus:border-gray-400"
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrorMessage((prev) => ({ ...prev, name: "" }));
            }}
            placeholder="이름을 입력해주세요"
            onKeyDown={handleKeyDown}
          />
          {showErrorMessage("confirmPassword") && (
            <div className="error">{showErrorMessage("name")}</div>
          )}

          <button
            className="w-full px-4 py-2 mb-6 bg-amber-400 rounded-[30px] shadow text-center text-gray-700 text-[15px] font-semibold"
            type="submit"
            onClick={emailSignUp}
          >
            회원가입
          </button>
        </form>
        <div className="w-full border-b border-gray-100" />
        <div className="w-full text-right mt-3 mb-4 text-zinc-500 text-sm font-medium">
          <button
            className="text-gray-500 hover:underline"
            onClick={() => navigate("/signIn")}
          >
            로그인 페이지로 돌아가기
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
