import { create } from "zustand"

const useStore = create((set) => ({
    hasFixedBottom: false,
    setHasFixedBottom: (flag) => set({ hasFixedBottom: flag }),
    isDark: false,
    setIsDark: (flag) => set({ isDark: flag }),
    newsId: 0,
    setNewsId: (id) => set({ newsId: id }),
    isOpen: 0,
    setIsOpen: (flag) => set({ isOpen: flag }), // 0:close, 1:open, 2: Force open only once & change 1
    closeSlide: () => set({ newsId: 0, isOpen: 0 }),
    searchText: "",
    setSearchText: (text) => set({ searchText: text }),
    isLogin: localStorage.getItem("token") != null,
    setIsLogin: (flag) => set({ isLogin: flag }),
    loading: false,
    setLoading: (flag) => set({ loading: flag }),
    apiRunning: false,
    setApiRunning: (flag) => set({ apiRunning: flag }),
    onboardingValue: {},
    setOnboardingValue: (onboardingValue) => set({ onboardingValue }),
    commonMealList: [],
    setCommonMealList: (commonMealList) => set({commonMealList}),
    commonMealDetailObj: {},
    setCommonMealDetailObj: (commonMealDetailObj) => set({commonMealDetailObj}),
}))

export default useStore
