import React, { useState, useEffect } from "react";
import "./component.css";
import { useAxiosWrapper } from "../common";
import useStore from "../commonStore";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { supbaseWrapper } from "../supabaseClient";

const SearchBar = ({ data }) => {
  const { api } = useAxiosWrapper();
  const [text, setText] = useState("");
  const { searchText, setSearchText } = useStore();
  const navigate = useNavigate();

  const searchAndMove = async () => {
    setSearchText(text);
    navigate("/recipeResultList");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchAndMove();
    }
  };

  return (
    <div className="search-bar">
      <input
        className="py-2 px-4"
        type="search"
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="원하는 재료 / 요리를 입력해주세요."
      />
    </div>
  );
};

const SearchList = () => {
  return <div className="search-list"></div>;
};

const QnaList = () => {
  const { api } = useAxiosWrapper();
  const [qnaList, setQnaList] = useState([]);
  const getQnaList = async () => {
    try {
      const response = await api({ method: "GET", url: `/api/qna/list` });
      setQnaList(response.data || []);
    } catch (error) {
      console.error("Error fetching question list:", error);
    }
  };

  useEffect(() => {
    getQnaList();
  }, []);

  if (!Array.isArray(qnaList) && qnaList.length < 1) {
    return <div className="qna-list">QnA 데이터가 없습니다.</div>;
  } else {
    return (
      <div className="qna-list theme-component-background-color">
        <h3>QnA List</h3>
        {qnaList.map((item) => {
          return (
            <div key={item.id}>
              <div>질문 내용 : {item.question}</div>
              <div>정답 ID : {item.answerId}</div>
            </div>
          );
        })}
      </div>
    );
  }
};

const CarouselComponent = () => {
  return (
    <Carousel
      autoPlay
      interval={3000}
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      showIndicators={true}
    >
      <div>
        <img src="/static/banner1.png" alt="banner" />
      </div>
      <div>
        <img src="/static/banner2.png" alt="banner" />
      </div>
      <div>
        <img src="/static/banner3.png" alt="banner" />
      </div>
    </Carousel>
  );
};
const HeaderContent = ({ isLogin, onSearch, onLogout }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row justify-end items-center w-full">
      {isLogin ? (
        <>
          <button
            className="flex text-xs mb:text-sm text-gray-500"
            onClick={onSearch}
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/search.png`}
              alt="아이콘"
              className="w-6 ml-1 me-3"
            />
          </button>
          <button
            className="flex items-center text-sm text-xs mb:text-sm text-gray-500"
            onClick={onLogout}
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/sign_out.png`}
              alt="아이콘"
              className="w-6 h-6 ml-1"
            />
          </button>
        </>
      ) : (
        <button
          className="flex items-center text-xs mb:text-sm text-gray-500"
          onClick={() => navigate("/signIn")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/login.png`}
            alt="아이콘"
            className="w-6 ml-1"
          />
        </button>
      )}
    </div>
  );
};

const OnboardingHeader = ({
  currentStep,
  onboardingValue,
  setOnboardingValue,
}) => {
  const { isLogin, setIsLogin } = useStore();
  const navigate = useNavigate();
  const { updateSurvey } = supbaseWrapper();

  const updateData = async (params) => {
    await updateSurvey(onboardingValue.id, params);
  };

  useEffect(() => {
    if (onboardingValue.startIntro !== true) {
      navigate("/");
    } else {
      if (!location.state || location.state.fromInputCheck !== true) {
        updateData({ lastStep: currentStep });
        setOnboardingValue({ ...onboardingValue, step: currentStep });
      }
    }
  }, []);

  const emailLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    setIsLogin(false);
    navigate("/");
  };

  return (
    <div className="absolute top-0 left-0 right-0 flex justify-between items-center py-5 px-3  bg-white">
      <div className="absolute left-3">
        <button
          className="text-gray-500"
          onClick={() => navigate(`/step${currentStep - 1}`)}
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/back.png`}
            alt="아이콘"
            className="w-3 h-5"
          />
        </button>
      </div>
      <div className="flex-grow flex justify-center">
        <img
          src={`${process.env.PUBLIC_URL}/static/omjm.png`}
          alt="아이콘"
          className="w-10"
          onClick={() => navigate("/")}
        />
      </div>
      <div className="absolute right-3">
        <HeaderContent
          isLogin={isLogin}
          onSearch={() => navigate("/search")}
          onLogout={emailLogout}
        />
      </div>
    </div>
  );
};


const Header = ({ showBackButton = false, showLogo = true }) => {
  const { isLogin, setIsLogin } = useStore();
  const navigate = useNavigate();

  const emailLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    setIsLogin(false);
    navigate("/");
  };

  return (
    <div className="absolute top-0 py-5 px-3 left-0 right-0 flex justify-between items-center bg-white z-10">
      {showBackButton && (
        <button className="text-gray-500" onClick={() => navigate(-1)}>
          <img
            src={`${process.env.PUBLIC_URL}/static/back.png`}
            alt="아이콘"
            className="w-3 h-5"
          />
        </button>
      )}
      <div className="flex-grow"></div>
      {showLogo && (
        <div className="absolute left-1/2 transform -translate-x-1/2">
          <img
            src={`${process.env.PUBLIC_URL}/static/omjm.png`}
            alt="아이콘"
            className="w-10"
            onClick={() => navigate("/")}
          />
        </div>
      )}
      <HeaderContent
        isLogin={isLogin}
        onSearch={() => navigate("/search")}
        onLogout={emailLogout}
      />
    </div>
  );
};

const Loading = () => {
  return (
    <div className="indicator-wrapper">
      <img
        src={`${process.env.PUBLIC_URL}/static/omjm.png`}
        alt="Loading"
        className="inner-image"
      />
      <div className="circle">
        <div className="inner-circle"></div>
      </div>
    </div>
  );
};

export {
  SearchBar,
  SearchList,
  QnaList,
  CarouselComponent,
  OnboardingHeader,
  Header,
  Loading,
};
