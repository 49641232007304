// ProductDetail.js
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header, Loading } from "../component";
import {
  ProductHeader,
  NutrientInfo,
  RestaurantInfo,
  OtherMenus,
  useProductData,
  ProductInfo,
} from "./productComponent";

const ProductDetail = () => {
  const { productDetail, isLoading, error, fetchProductDetail } =
    useProductData();
  const location = useLocation();
  const { id } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id") || (location.state && location.state.id); // 수정: 쿼리 파라미터와 state에서 id를 가져옴

    console.log("Product ID:", id); // 디버깅 로그 추가

    if (!id) {
      navigate("/error");
    } else {
      fetchProductDetail(id);
    }
  }, [location, navigate]);

  useEffect(() => {
    if (location.state && location.state.id) {
      fetchProductDetail(location.state.id);
    }
  }, [location.state, fetchProductDetail]); // 새로운 useEffect 훅 추가

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!productDetail) {
    return <div>No product detail available</div>;
  }

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="flex flex-col items-center pt-20 px-3 justify-center w-full md:w-1/2 lg:w-2/5 bg-white relative h-screen overflow-auto">
        <Header showBackButton={true} />
        <div className="flex flex-col items-center gap-14 h-full overflow-y-auto">
          <ProductHeader productDetail={productDetail} />
          {productDetail.nutrient && (
            <NutrientInfo nutrientInfo={productDetail.nutrient} />
          )}
          {productDetail.center && (
            <RestaurantInfo productDetail={productDetail} />
          )}
          {productDetail.product_url && (
            <ProductInfo productDetail={productDetail} />
          )}
          <div className="w-full border-b border-gray-100" />
          <OtherMenus
            brandName={productDetail.brand_name}
            relatedProducts={productDetail.relatedProducts}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
