import React, { useEffect } from "react";
import {
  useMealData,
  KakaoShareButton,
  DaySelector,
  DailyMealItem,
} from "./mealComponent";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Header, Loading } from "../component";

const getTodayIndex = (dates) => {
  const today = new Date().toISOString().split("T")[0];
  return dates.indexOf(today);
};

const DayMeals = () => {
  const navigate = useNavigate();
  const { surveyId } = useParams();

  const { data, selectedDay, setSelectedDay, handleMealClick, scrollAreaRef } =
    useMealData(surveyId, false);

  useEffect(() => {
    if (data.length > 0) {
      const mealPlanDates = data.map((item) => item.date);
      const todayIndex = getTodayIndex(mealPlanDates);
      if (todayIndex != -1) {
        setSelectedDay(todayIndex);
      }
    }
  }, [data]);

  if (!surveyId) {
    // surveyId가 없는 경우 처리
    return <div>잘못된 접근입니다. surveyId가 필요합니다.</div>;
  }
  if (!data || data.length === 0) return <Loading />;

  const mealPlanDates = data.map((item) => item.date);

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 lg:w-2/5 bg-white px-2 relative h-screen overflow-auto">
        <div
          ref={scrollAreaRef}
          className="flex flex-col w-full overflow-y-auto pb-10"
        >
          <Header showBackButton={true} />
          <h1 className=" text-center text-xl font-semibold text-gray-700  mt-16 pt-6">
            하루 식단 계획
          </h1>
          <div className=" flex justify-end items-center p-2 mb-4 "></div>
          <DaySelector
            dates={mealPlanDates}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
          />
          {["아침", "점심", "저녁"].map((mealType, index) => {
            let mealData = data[selectedDay]?.meals.find(
              (meal) => meal.mealType == mealType
            );
            return (
              <DailyMealItem
                key={`${mealData?.id || index}-${index}`}
                meal={mealData}
                mealType={mealType}
                dayMealId={data[selectedDay].dayMealId}
                onClick={(id, dataType) =>
                  handleMealClick(id, dataType, surveyId)
                }
                surveyId={surveyId}
              />
            );
          })}
          <ToastContainer />
          <button
            className="w-full py-2 my-10 bg-amber-400 text-gray-700 font-semibold rounded-full shadow-md"
            onClick={() => navigate(`/weeklyMeals/${surveyId}`)}
          >
            주간 전체 식단 보기
          </button>
        </div>

        <div className="absolute bottom-0 flex justify-between items-center w-full px-4 bg-white py-3">
          <KakaoShareButton data={data} surveyId={surveyId} />
          <div className="flex items-center space-x-3">
            <button className="w-8 h-8">
              <img
                src={`${process.env.PUBLIC_URL}/static/capture.png`}
                alt="캡처"
                className="w-full h-full object-contain"
              />
            </button>
            <button className="w-8 h-8">
              <img
                src={`${process.env.PUBLIC_URL}/static/pdf.png`}
                alt="PDF"
                className="w-full h-full object-contain"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DayMeals;
