import React from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Intro from "./page/onboarding/Intro";
import MealTarget from "./page/onboarding/MealTarget";
import SelectMealDay from "./page/onboarding/SelectMealDay";
import MealCategory from "./page/onboarding/MealCategory";
import Area from "./page/onboarding/Area";
import ExcludedIngredients from "./page/onboarding/ExcludedIngredients";
import Health from "./page/onboarding/Health";
import EatingHabits from "./page/onboarding/EatingHabits";
import InputCheck from "./page/onboarding/InputCheck";
import FoodInfo from "./page/detail/productDetail";
import RecipeDetail from "./page/detail/recipeDetail";
import LoginPage from "./page/user/signIn";
import SignUp from "./page/user/signUp";
import RecipeResultList from "./page/recipeResultList";
import DayMeals from "./page/plan/dailyMeals";
import WeeklyMeals from "./page/plan/weeklyMeals";

const App = () => {
  return (
    <main className="app-content2 w-full h-full full-height-element overflow-y-auto">
      <Routes>
        <Route path="/" element={<Intro />} />
        <Route path="/step1" element={<MealCategory />} />
        <Route path="/step2" element={<SelectMealDay />} />
        <Route path="/step3" element={<Health />} />
        <Route path="/step4" element={<EatingHabits />} />
        <Route path="/step5" element={<ExcludedIngredients />} />
        <Route path="/inputCheck" element={<InputCheck />} />

        <Route path="/intro" element={<Intro />} />
        <Route path="/mealCategory" element={<MealCategory />} />
        <Route path="/health" element={<Health />} />
        <Route path="/eatingHabits" element={<EatingHabits />} />
        <Route path="/excludedIngredients" element={<ExcludedIngredients />} />
        <Route path="/inputCheck" element={<InputCheck />} />
        <Route path="/mealTarget" element={<MealTarget />} />
        <Route path="/selectMealDay" element={<SelectMealDay />} />
        <Route path="/area" element={<Area />} />
        <Route path="/weeklyMeals/:surveyId" element={<WeeklyMeals />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/productDetail" element={<FoodInfo />} />
        <Route path="/recipeDetail" element={<RecipeDetail />} />
        <Route path="/signIn" element={<LoginPage />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/search" element={<RecipeResultList />} />
        <Route path="/dailyMeals/:surveyId" element={<DayMeals />} />
      </Routes>
    </main>
  );
};

const AppWrapper = () => {
  return (
    <div className="app-container">
      <ToastContainer position="top-center" autoClose={2000} />
      <Router>
        <App />
      </Router>
    </div>
  );
};

export default AppWrapper;
