import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../commonStore";
import { supbaseWrapper } from "../../supabaseClient";
import { Header } from "../component";

const InputCheck = () => {
  const { onboardingValue, setOnboardingValue } = useStore();
  const navigate = useNavigate();
  const { isLogin, setIsLogin } = useStore();
  const { upsertSurvey, createPlan } = supbaseWrapper();

  const updateData = useCallback(async (params) => {
    await upsertSurvey(params);
  }, []);

  const createMealPlan = useCallback(async (params) => {
    await createPlan({
      survey_id: onboardingValue.id,
      meal_categories: onboardingValue.mealCategory,
      area: onboardingValue.area,
      eating_habits: onboardingValue.eatingHabits,
      health: onboardingValue.health,
      excluded_ingredients: onboardingValue.excludedIngredients,
      dates: onboardingValue.dates,
    });
    navigate(`/dailyMeals/${onboardingValue.id}`);
  }, []);

  useEffect(() => {
    if (onboardingValue.startIntro !== true) {
      navigate("/");
    } else {
      let params = { ...onboardingValue, lastStep: "inputCheck" };
      updateData(params);
      setOnboardingValue(params);
    }
  }, []);

  const handleEdit = (step) => {
    navigate(step, { state: { fromInputCheck: true } });
  };

  const checkArray = (arr) => {
    return Array.isArray(arr) && arr.length > 0;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const days = ["일", "월", "화", "수", "목", "금", "토"];
    return `${date.getMonth() + 1}월 ${date.getDate()}일 (${
      days[date.getDay()]
    })`;
  };

  const getDateRange = (startDateString) => {
    if (!startDateString) {
      return { start: null, end: null };
    }
    const startDate = new Date(startDateString);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);
    return {
      start: startDate,
      end: endDate,
    };
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 lg:w-2/5 bg-white p-6 md:p-16 relative h-screen overflow-y-auto">
        <Header showBackButton={true} />
        <h1 className="text-2xl font-semibold text-gray-700 text-center mb-8 mt-20 md:mt-0">
          선택한 내용을 확인해 주세요
        </h1>

        <div className="w-full">
          <div className=" bg-gray-50 shadow-lg rounded-lg p-5">
            <div className="mb-8">
              <h2 className="text-base font-semibold mb-2 mt-3 text-gray-700 ">
                식사 방법
              </h2>
              <div className="flex justify-between items-center">
                {checkArray(onboardingValue.mealCategory) ? (
                  <>
                    <p className="text-sm text-gray-700 ">
                      {" "}
                      {onboardingValue.mealCategory
                        .filter((e) => e !== "")
                        .join(", ")}
                    </p>
                  </>
                ) : (
                  "선택 사항 없음"
                )}
                <button
                  className="text-gray-500 text-sm"
                  onClick={() => handleEdit("/mealCategory")}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/static/refresh.png`}
                    alt="아이콘"
                    className="w-5"
                  />
                </button>
              </div>
            </div>

            <div className="mb-8">
              <h2 className="text-base font-semibold mb-2 text-gray-700 ">
                식단 기간
              </h2>
              <div className="flex justify-between items-center">
                {onboardingValue.dates
                  ? (() => {
                      const { start, end } = getDateRange(
                        onboardingValue.dates
                      );
                      if (start && end) {
                        return (
                          <div className="text-sm text-gray-700">
                            <p>
                              {formatDate(start)} ~ {formatDate(end)}
                            </p>
                            <p className="text-xs mt-1 text-gray-500">
                              총 7일간의 식단이 생성됩니다.
                            </p>
                          </div>
                        );
                      } else {
                        return "유효한 날짜 정보가 없습니다.";
                      }
                    })()
                  : "선택사항 없음"}
                <button
                  className="text-gray-500 text-sm"
                  onClick={() => handleEdit("/selectMealDay")}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/static/refresh.png`}
                    alt="아이콘"
                    className="w-5"
                  />
                </button>
              </div>
            </div>

            {checkArray(onboardingValue.area) ? (
              <div className="mb-8">
                <h2 className="text-base font-semibold mb-2 text-gray-700 ">
                  지역
                </h2>
                <div className="flex justify-between items-center">
                  <p className="text-sm text-gray-700 ">
                    {onboardingValue.area.filter((e) => e !== "").join(", ")}
                  </p>
                  <button
                    className="text-gray-500 text-sm"
                    onClick={() => handleEdit("/area")}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/static/refresh.png`}
                      alt="아이콘"
                      className="w-5"
                    />
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="mb-8">
              <h2 className="text-base font-semibold mb-2 text-gray-700 ">
                건강 상태
              </h2>
              <div className="flex justify-between items-center">
                {checkArray(onboardingValue.health) ? (
                  <>
                    <p className="text-sm text-gray-700 ">
                      {" "}
                      {onboardingValue.health
                        .filter((e) => e !== "")
                        .join(", ")}
                    </p>
                  </>
                ) : (
                  "선택 사항 없음"
                )}
                <button
                  className="text-gray-500 text-sm"
                  onClick={() => handleEdit("/health")}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/static/refresh.png`}
                    alt="아이콘"
                    className="w-5"
                  />
                </button>
              </div>
            </div>

            <div className="mb-8">
              <h2 className="text-base font-semibold mb-2 text-gray-700 ">
                식사 스타일
              </h2>
              <div className="flex justify-between items-center">
                {checkArray(onboardingValue.eatingHabits) ? (
                  <>
                    <p className="text-sm text-gray-700 ">
                      {" "}
                      {onboardingValue.eatingHabits
                        .filter((e) => e !== "")
                        .join(", ")}
                    </p>
                  </>
                ) : (
                  "선택 사항 없음"
                )}
                <button
                  className="text-gray-500 text-sm"
                  onClick={() => handleEdit("/eatingHabits")}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/static/refresh.png`}
                    alt="아이콘"
                    className="w-5"
                  />
                </button>
              </div>
            </div>

            <div className="mb-4">
              <h2 className="text-base font-semibold text-gray-700 mb-2">
                제외한 재료
              </h2>
              <div className="flex justify-between items-center">
                {checkArray(onboardingValue.excludedIngredients) ? (
                  <>
                    <p className="text-sm text-gray-700">
                      {" "}
                      {onboardingValue.excludedIngredients
                        .filter((e) => e !== "")
                        .join(", ")}
                    </p>
                  </>
                ) : (
                  "선택 사항 없음"
                )}
                <button
                  className="text-gray-500 text-sm"
                  onClick={() => handleEdit("/excludedIngredients")}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/static/refresh.png`}
                    alt="아이콘"
                    className="w-5"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-8 mb-8">
          <button
            className="w-full py-2 bg-amber-400 font-semibold text-gray-700 rounded-full shadow-md"
            onClick={() => {
              createMealPlan();
            }}
          >
            나만을 위한 식단 생성
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputCheck;
