// mealComponent.js
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../commonStore";
import { supbaseWrapper } from "../../supabaseClient";
import { toast } from "react-toastify";

export const useMealData = (surveyId, isWeekly = false) => {
  // const { onboardingValue } = useStore();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedDay, setSelectedDay] = useState(0);
  const headerRef = useRef(null);
  const scrollAreaRef = useRef(null);
  const { getMealPlan } = supbaseWrapper();

  useEffect(() => {
    const fetchData = async () => {
      if (!surveyId) {
        console.error("surveyId 없음");
        return;
      }

      try {
        // API에서 meal plan 데이터 가져오기
        const response = await getMealPlan(surveyId);
        console.log(response); // API 응답을 확인하기 위해 추가
        if (response.error) {
          throw new Error(response.error.message);
        }
        const mealPlanData = response.data || {};
        const mealPlan = mealPlanData.mealPlan || {}; // mealPlan만 추출
        console.log(mealPlanData); // 데이터를 콘솔에 출력하여 확인

        //날짜 순으로 정렬
        const sortedDates = Object.keys(mealPlan).sort();

        let mealList = sortedDates.map((date) => {
          const dayMeals = ["breakfast", "lunch", "dinner"]
            .map((mealType) => {
              const meal = mealPlan[date][mealType];
              if (meal) {
                if (meal.type === "공복") {
                  return {
                    mealType:
                      mealType === "breakfast"
                        ? "아침"
                        : mealType === "lunch"
                        ? "점심"
                        : "저녁",
                    dataType: "공복",
                    name: "공복",
                  };
                } else if (meal.details) {
                  return {
                    id: meal.details.id,
                    dataType: meal.type,
                    image: meal.details.img_url,
                    name: meal.details.summary || meal.details.name,
                    title: meal.details.title,
                    mealType:
                      mealType === "breakfast"
                        ? "아침"
                        : mealType === "lunch"
                        ? "점심"
                        : "저녁",
                    category: meal.details.category || "",
                    brandName: meal.details.brand_name,
                    price: meal.details.price,
                    ingredients: meal.details.ingredients || "",
                  };
                }
              }
              return null;
            })
            .filter((meal) => meal !== null);
          return {
            date: date,
            meals: dayMeals,
            dayMealId: mealPlan[date]["dayMealId"],
          };
        });
        setData(mealList);
      } catch (error) {
        console.error("식단계획 데이터 가져오기 실패 : ", error);
        toast.error("식단 정보를 불러오는데 실패했습니다.");
      }
    };
    fetchData();
  }, [surveyId]);

  const handleMealClick = useCallback(
    (id, dataType, surveyId) => {
      console.log(
        `Navigating with id: ${id}, dataType: ${dataType}, surveyId: ${surveyId}`
      );
      if (dataType === "레시피") {
        navigate(`/recipeDetail`, { state: { id, surveyId } });
      } else if (dataType === "상품") {
        navigate(`/productDetail`, { state: { id, surveyId } });
      } else if (dataType === "공복") {
        return;
      } else {
        toast.error(`식단 정보가 존재하지 않습니다!`);
        console.error(`유효하지 않은 값입니다. (dataType: ${dataType})`);
      }
    },
    [navigate]
  );

  return {
    data,
    selectedDay,
    setSelectedDay,
    handleMealClick,
    headerRef,
    scrollAreaRef,
  };
};

export const WeeklyMealItem = ({
  meal,
  dayMealId,
  mealType,
  onClick,
  surveyId,
}) => {
  let mainContent;
  const [showPopup, setShowPopup] = useState(false);
  let timer;
  const handleMouseDown = (event) => {
    timer = setTimeout(() => {
      setShowPopup(true);
    }, 700);
  };
  const handleMouseUp = () => {
    clearTimeout(timer);
  };
  if (!meal) {
    return (
      <>
        <div
          className="bg-green-100 flex flex-col items-center bg-white gap-1 cursor-pointer border border-[#E6E6E6] rounded-lg pb-3 shadow"
          onClick={() => {
            if (showPopup == false) {
              setShowPopup(true);
            }
          }}
        >
          <div className="text-sm font-semibold w-full text-center leading-[22px] py-1 border-b border-[#E6E6E6]">
            {mealType}
          </div>
          <div className="px-2 h-full flex items-center text-xs md:text-sm text-center text-gray-700 font-semibold overflow-hidden whitespace-normal line-clamp-2">
            + 식사 선택하기
          </div>
        </div>
        <ChangeMealPopup
          show={showPopup}
          dayMealId={dayMealId}
          mealType={mealType}
          onClose={() => setShowPopup(false)}
        />
      </>
    );
  }
  if (meal.dataType === "레시피" || meal.dataType === "상품") {
    mainContent = (
      <>
        <div className="text-sm font-semibold w-full text-center leading-[22px] py-1 border-b border-[#E6E6E6]">
          {meal.mealType}
        </div>
        <div className="px-3 py-2 w-full">
          <div className="w-full aspect-w-16 aspect-h-12 shadow-lg rounded-lg">
            <img
              src={meal.image}
              alt={meal.name}
              className="w-full h-full object-cover rounded-lg"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "default_image.jpg";
              }}
            />
          </div>
        </div>
        <div className="justify-around w-full px-2 flex flex-col h-full text-xs md:text-sm items-center text-center text-gray-700 overflow-hidden whitespace-normal line-clamp-2 reading-2">
          <span className="text-xs flex-wrap bg-amber-100 rounded-full px-2 leading-[18px] me-1 w-full text-center mb-2">
            {meal.category}
          </span>
          {meal.name}
        </div>
      </>
    );
  } else if (meal.dataType === "공복") {
    mainContent = (
      <>
        <div className="text-sm font-semibold w-full text-center leading-[22px] py-1 border-b border-[#E6E6E6]">
          {meal.mealType}
        </div>
        <div className="p-2 w-full">
          <div className="w-full aspect-w-16 aspect-h-12">
            <img
              src={`${process.env.PUBLIC_URL}/static/empty_stomach.jpg`}
              alt="공복시간"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>
        <div className="px-2 h-full flex items-center text-xs md:text-sm text-center text-gray-700 font-semibold overflow-hidden whitespace-normal line-clamp-2">
          공복시간
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className="flex flex-col items-center bg-white gap-1 cursor-pointer border border-[#E6E6E6] rounded-lg pb-3 shadow"
        onMouseDown={(event) => {
          event.preventDefault();
          handleMouseDown();
        }}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
        onContextMenu={(e) => e.preventDefault()}
        onClick={() => {
          if (showPopup == false) {
            onClick(meal.id, meal.dataType, surveyId);
          }
        }}
      >
        {mainContent}
      </div>
      <ChangeMealPopup
        show={showPopup}
        dayMealId={dayMealId}
        mealType={mealType}
        onClose={() => setShowPopup(false)}
      />
    </>
  );
};

export const DailyMealItem = ({
  meal,
  dayMealId,
  surveyId,
  mealType,
  onClick,
}) => {
  let mainContent;

  const [showPopup, setShowPopup] = useState(false);
  let timer;
  const handleMouseDown = (event) => {
    timer = setTimeout(() => {
      setShowPopup(true);
    }, 700);
  };
  const handleMouseUp = () => {
    clearTimeout(timer);
  };

  // es와 db의 데이터 불일치. id 값이 db에 없는 경우 선택 가능하도록 함
  if (!meal) {
    return (
      <>
        <div
          className="flex flex-row items-center bg-white gap-1 px-2 py-3 cursor-pointer border-b"
          onClick={() => {
            if (showPopup == false) {
              setShowPopup(true);
            }
          }}
        >
          <div className="flex container items-center justify-center bg-green-100 rounded-lg w-full aspect-w-16 aspect-h-5">
            <div className="text-sm text-center md:text-sm text-left text-gray-700 font-semibold md:py-20 py-12 overflow-hidden whitespace-normal line-clamp-2">
              + 식사 선택하기
            </div>
          </div>
        </div>
        <ChangeMealPopup
          show={showPopup}
          dayMealId={dayMealId}
          mealType={mealType}
          onClose={() => setShowPopup(false)}
        />
      </>
    );
  }

  if (meal.dataType === "레시피" && meal.ingredients) {
    const mainIngredients = Object.keys(JSON.parse(meal.ingredients)).slice(
      0,
      4
    );
    mainContent = (
      <>
        <div className="w-2/5 aspect-w-16 aspect-h-5">
          <img
            src={meal.image}
            alt={meal.name}
            className="w-full h-full object-cover rounded-lg"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `${process.env.PUBLIC_URL}/static/empty_stomach.jpg`;
            }}
          />
        </div>
        <div className="flex flex-col w-3/5 md:gap-3 gap-3 ml-1">
          <div className="text-sm md:text-sm items-center justify-start text-gray-700 font-semibold overflow-hidden whitespace-normal line-clamp-2">
            <span className="text-xs flex-wrap bg-amber-100 rounded-full py-1 px-2 me-1">
              {meal.category}
            </span>
            {meal.name}
          </div>
          <div className="flex flex-wrap gap-1 mt-2">
            {mainIngredients.map((ingredient, index) => (
              <div
                key={index}
                className="text-xs text-gray-500 bg-amber-100 rounded-full py-1 px-2"
              >
                # {ingredient.trim()}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  } else if (meal.dataType === "상품") {
    mainContent = (
      <>
        <div className="w-2/5 aspect-w-16 aspect-h-5">
          <img
            src={meal.image}
            alt={meal.name}
            className="w-full h-full object-cover rounded-lg"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `${process.env.PUBLIC_URL}/static/empty_stomach.jpg`;
            }}
          />
        </div>
        <div className="flex flex-col w-3/5 ml-1">
          <div className="text-sm md:text-sm items-center text-left text-gray-700 font-semibold overflow-hidden whitespace-normal line-clamp-2">
            <span className="text-xs flex-wrap bg-amber-100 rounded-full py-1 px-2 me-1">
              {meal.category}
            </span>
            {meal.name}
          </div>
          <div className="flex flex-col mt-2">
            {meal.price && (
              <div className="text-xs font-semibold px-1 mb-1">
                {meal.price
                  ? `${meal.price.toLocaleString()}원`
                  : "가격 정보 없음"}
              </div>
            )}
            {meal.brandName && (
              <div className="text-xs px-1 text-right text-amber-500 font-semibold">
                {meal.brandName}
              </div>
            )}
          </div>
        </div>
      </>
    );
  } else if (meal.dataType === "공복") {
    mainContent = (
      <>
        <div className="flex container items-center justify-center bg-amber-100 rounded-lg w-full aspect-w-16 aspect-h-5">
          <div
            className="text-sm text-center md:text-sm text-left text-gray-700 font-semibold md:py-20 py-12 overflow-hidden whitespace-normal line-clamp-2"
            onClick={() => {
              if (showPopup == false) {
                setShowPopup(true);
              }
            }}
          >
            공복 시간
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className="flex flex-row items-center bg-white gap-1 px-2 py-3 cursor-pointer border-b"
        onMouseDown={(event) => {
          event.preventDefault();
          handleMouseDown();
        }}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
        onContextMenu={(e) => e.preventDefault()}
        onClick={() => {
          if (showPopup == false) {
            if (meal.dataType === "레시피") {
              onClick(meal.id, meal.dataType, surveyId);
            } else {
              onClick(meal.id, meal.dataType);
            }
          }
        }}
      >
        {mainContent}
      </div>
      <ChangeMealPopup
        show={showPopup}
        dayMealId={dayMealId}
        mealType={mealType}
        onClose={() => setShowPopup(false)}
      />
    </>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}`;
};

export const DaySelector = ({ dates, selectedDay, setSelectedDay }) => (
  <div className="flex justify-between w-full px-4 pb-3 border-b">
    {dates.map((date, index) => (
      <button
        key={date}
        className={`text-sm ${
          index === selectedDay
            ? "bg-amber-400 text-gray700 font-semibold rounded-full w-10 h-10 flex items-center justify-center"
            : "text-gray-700 font-semibold "
        }`}
        onClick={() => setSelectedDay(index)}
      >
        {formatDate(date)}
      </button>
    ))}
  </div>
);

export const KakaoShareButton = ({ data, surveyId }) => {
  // const omjm = "https://ai-coding-experts.github.io";
  const omjm =
    process.env.REACT_APP_TYPE == "dev"
      ? "https://www.omjm.site/"
      : "http://127.0.0.1:3000/omjm";
  const kakaoKey = process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY;

  const shareKakao = () => {
    const kakao = window.Kakao;

    if (!kakao) {
      console.error("Kakao SDK가 로드되지 않았습니다.");
      return;
    }

    if (!kakao.isInitialized()) {
      if (!kakaoKey) {
        console.error("Kakao JavaScript 키가 설정되지 않았습니다.");
        return;
      }
      kakao.init(kakaoKey);
    }

    const today = new Date().toISOString().split("T")[0];
    const todayMeals = data.find((day) => day.date === today)?.meals || [];

    console.log("Today's date:", today);
    console.log("Today's meals:", todayMeals);

    const contents = todayMeals.map((meal) => {
      let detailLink = `${omjm}/#/dailyMeals/${surveyId}`;
      if (meal.dataType === "상품") {
        detailLink = `${omjm}/#/productDetail?id=${meal.id}`;
      } else if (meal.dataType === "레시피") {
        detailLink = `${omjm}/#/recipeDetail?id=${meal.id}`;
      }
      // else if (meal.dataType === "공복") {
      //   detailLink = `${omjm}/#/dailyMeals/${surveyId}`;
      // }

      if (meal.dataType === "공복") {
        return {
          title: `[${meal.mealType}] 공복`,
          description: "공복 시간",
          imageUrl: `https://cdn.skkuw.com/news/photo/201906/21163_10143_5027.jpg`,
          link: {
            mobileWebUrl: detailLink,
            webUrl: detailLink,
          },
        };
      } else {
        return {
          title: `[${meal.mealType}] ${meal.name}`,
          description: meal.brandName || meal.category,
          imageUrl:
            meal.image ||
            `https://cdn.skkuw.com/news/photo/201906/21163_10143_5027.jpg`,
          link: { mobileWebUrl: detailLink, webUrl: detailLink },
        };
      }
    });
    // 컨텐츠가 비어있는 경우 처리
    if (contents.length === 0) {
      contents.push({
        title: "오늘의 식단 정보 없음",
        description: "오늘의 식단 정보가 없습니다.",
        imageUrl: `${process.env.PUBLIC_URL}/static/no_meal_info.jpg`,
        link: { mobileWebUrl: omjm, webUrl: omjm },
      });
    }

    console.log("Kakao share contents:", contents);

    kakao.Share.sendDefault({
      objectType: "list",
      headerTitle: `[${today}] 오밀조밀 식단`,
      headerLink: {
        mobileWebUrl: `${omjm}/#/dailyMeals/${surveyId}`,
        webUrl: `${omjm}/#/dailyMeals/${surveyId}`,
      },
      contents: contents,
      buttons: [
        {
          title: "식단 보러가기",
          link: {
            mobileWebUrl: `${omjm}/#/dailyMeals/${surveyId}`,
            webUrl: `${omjm}/#/dailyMeals/${surveyId}`,
          },
        },
      ],
    });
  };

  return (
    <img
      src={`${process.env.PUBLIC_URL}/static/kakao-sm.png`}
      alt="카카오톡 공유"
      className="h-9 cursor-pointer"
      onClick={shareKakao}
    />
  );
};

export const ChangeMealPopup = ({ show, onClose, dayMealId, mealType }) => {
  const { changeMeal } = supbaseWrapper();
  if (!show) return null;

  const mealFetch = async (category) => {
    let data = await changeMeal(dayMealId, mealType, category);

    if (data == "ok") {
      window.location.reload();
    } else {
      toast.error("새로운 식단 정보가 없습니다.");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
      <div className="w-4/5 bg-white rounded-[15px] shadow-lg text-center">
        {["요리레시피", "간편레시피", "식당", "도시락", "편의점", "공복"].map(
          (category, index) => (
            <button
              key={index}
              className="w-full py-4 border-b border-gray-200 text-gray-800 font-medium text-base"
              onClick={() => mealFetch(category)}
            >
              {category}
            </button>
          )
        )}
        <button
          className="w-full py-4 text-gray-800 font-medium text-base bg-gray-300 rounded-b-[13px]"
          onClick={onClose}
        >
          닫기
        </button>
      </div>
    </div>
  );
};
