import React, { useState } from "react";
import { useAxiosWrapper } from "../../common";
import { useNavigate } from "react-router-dom";
import useStore from "../../commonStore";
import { Link } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const { api } = useAxiosWrapper();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setIsLogin } = useStore();

  const emailLogin = async (e) => {
    e.preventDefault();
    try {
      if (email == "" || !email) {
        alert("이메일을 입력해주세요.");
        return;
      }
      if (password == "" || !password) {
        alert("비밀번호를 입력해주세요.");
        return;
      }

      const data = new URLSearchParams();
      data.append("username", email);
      data.append("password", password);

      const response = await api({
        method: "POST",
        url: "api/user/login",
        data: data.toString(),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });

      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("email", response.data.email);
      localStorage.setItem("name", response.data.name);

      setIsLogin(true);
      navigate("/");
    } catch (error) {
      console.log(
        "error : ",
        error.response ? error.response.data : error.message
      );
      window.alert("로그인에 실패했습니다. 다시 시도해 주세요.");
    }
  };

  const snsLogin = (provider) => {
    alert(`${provider} 로그인은 준비 중입니다.`);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      emailLogin(e);
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 lg:w-2/5 bg-white p-6 md:p-16 relative h-screen overflow-auto">
        <div className="absolute top-2 left-0 right-0 flex justify-start p-4">
          <button
            className="text-gray-500 flex items-center text-sm"
            onClick={() => navigate(-1)}
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/back.png`}
              alt="아이콘"
              className="w-3 h-5 mr-2"
            />
          </button>
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/static/omjm_logo.png`}
          alt="아이콘"
          className="w-48 md:w-60 p-2"
        />
        <form className="w-full mt-4" onSubmit={emailLogin}>
          <input
            className="w-full px-4 py-2 mb-3 text-sm bg-white rounded-[30px] border border-neutral-200 focus:outline-none focus:border-gray-400"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="이메일을 입력하세요"
            onKeyDown={handleKeyDown}
            autoComplete="email"
          />

          <input
            className="w-full px-4 py-2 mb-3 text-sm bg-white rounded-[30px] border border-neutral-200 focus:outline-none focus:border-gray-400"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="비밀번호를 입력하세요"
            onKeyDown={handleKeyDown}
            autoComplete="current-password"
          />
          <button
            className="w-full px-4 py-2 mb-6 bg-amber-400 rounded-[30px] shadow text-center text-gray-700 text-[15px] font-semibold"
            onClick={emailLogin}
          >
            로그인
          </button>
        </form>
        <div className="w-full flex border-b border-gray-100" />
        <div className="flex items-end gap-28 justify-between">
          <button
            className="mt-3 items-center"
            onClick={() => snsLogin("kakao")}
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/login-kakao.png`}
              alt="아이콘"
              className="h-12"
            />
          </button>
          <div className="text-right mt-3 mb-4 text-zinc-500 text-sm font-medium">
            <Link to="/signUp" className="cursor-pointer hover:underline">
              회원가입
            </Link>{" "}
            |{" "}
            <Link to="/" className="cursor-pointer hover:underline">
              비밀번호 찾기
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
