import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useStore from "../../commonStore";
import { supbaseWrapper } from "../../supabaseClient";
import { OnboardingHeader } from "../component";

const Step1Who = () => {
  const { onboardingValue, setOnboardingValue } = useStore();
  const [selectedRecipients, setSelectedRecipients] = useState(
    onboardingValue.step1 || []
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { updateSurvey } = supbaseWrapper();

  const updateData = useCallback(
    async (params) => {
      await updateSurvey(onboardingValue.id, params);
    },
    [onboardingValue.id]
  );

  useEffect(() => {
    if (!onboardingValue.id) {
      navigate("/");
    } else {
      if (onboardingValue.lastStep !== "step1") {
        updateData({ lastStep: "step1" });
        setOnboardingValue({ ...onboardingValue, lastStep: "step1" });
      }
    }
  }, [onboardingValue, setOnboardingValue, navigate, updateData]);

  const handleButtonClick = (value) => {
    setSelectedRecipients((prev) => {
      if (prev.includes(value)) {
        return prev.filter((recipient) => recipient !== value);
      } else {
        return [...prev, value];
      }
    });
    console.log(value);
  };

  const handleNextClick = () => {
    if (selectedRecipients.length === 0) {
      toast.error("대상을 선택해주세요!");
      return;
    }
    updateData({ step1: selectedRecipients });
    setOnboardingValue({ ...onboardingValue, step1: selectedRecipients });

    if (location.state && location.state.fromInputCheck) {
      navigate("/step5");
    } else {
      navigate("/selectMealDay");
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 lg:w-2/5 bg-white p-6 md:p-16 relative h-screen overflow-auto">
        <OnboardingHeader
          currentStep={currentStep}
          onboardingValue={onboardingValue}
          setOnboardingValue={setOnboardingValue}
        />
        <h1 className="text-2xl font-semibold text-center mb-6 mt-16 md:mt-0">
          어떤 분을 위해 식단을 준비 할까요?
        </h1>

        <div className="w-full space-y-4">
          {["본인", "부모님", "배우자", "자녀", "동료"].map((label) => (
            <button
              key={label}
              className={`w-full py-3 border rounded-full text-center ${
                selectedRecipients.includes(label) ? "bg-yellow-200" : ""
              }`}
              onClick={() => handleButtonClick(label)}
            >
              {label}
            </button>
          ))}
        </div>

        <button
          className="w-full py-2 bg-amber-400 text-gray-700 font-semibold rounded-full shadow-md"
          onClick={handleNextClick}
        >
          {location.state && location.state.fromInputCheck
            ? "변경 완료"
            : "다음으로"}
        </button>
      </div>
    </div>
  );
};

export default Step1Who;
