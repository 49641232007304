import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useStore from "../../commonStore";
import { supbaseWrapper } from "../../supabaseClient";
import { OnboardingHeader } from "../component";
import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css"; // Calendar의 기본 스타일
import "./SelectedMealDay.css";

const currentStep = 2;

const SelectMealDay = () => {
  const { onboardingValue, setOnboardingValue } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { upsertSurvey } = supbaseWrapper();

  // 시작 날짜만 저장
  const [selectedStartDate, setSelectedStartDate] = useState(() => {
    if (onboardingValue.dates) {
      return new Date(onboardingValue.dates + "T00:00:00+09:00");
    }
    return null;
  });

  const updateData = useCallback(
    async (params) => {
      try {
        await upsertSurvey(params);
      } catch (error) {
        console.error("Update survey error:", error);
        toast.error("데이터 업데이트 중 오류가 발생했습니다.");
      }
    },[]);

  useEffect(() => {
    if (onboardingValue.startIntro !== true) {
      navigate("/");
    } else {
      if (!location.state || location.state.fromInputCheck !== true) {
        let params = {...onboardingValue, lastStep: currentStep}
        updateData(params);
        setOnboardingValue(params);
      }
    }
  }, []);

  const handleDateChange = (date) => {
    // 선택된 날짜를 한국 시간대로 설정
    const koreaDate = new Date(
      date.toLocaleString("en-US", { timeZone: "Asia/Seoul" })
    );
    setSelectedStartDate(koreaDate);
  };

  const handleNextClick = () => {
    if (!selectedStartDate) {
      toast.error("식단이 필요한 날짜를 선택해 주세요!");
      return;
    }

    // 한국 시간대로 날짜 포맷팅
    const formattedDates = selectedStartDate
      .toLocaleString("ko-KR", {
        timeZone: "Asia/Seoul",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split(". ")
      .join("-")
      .replace(/\.$/, "");

    let values = { ...onboardingValue, dates: formattedDates }
    updateData(values);
    setOnboardingValue(values);

    if (location.state && location.state.fromInputCheck === true) {
      navigate("/inputCheck");
    } else {
      navigate(`/step${currentStep + 1}`);
    }
  };

  // 7일 범위의 종료 날짜 계산
  const getEndDate = (startDate) => {

    if (!startDate) return null;
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);
    return endDate;
  };

  const formatDate = (date) => {

    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return "날짜 선택";
    }
    return date.toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    });
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full md:w-1/2 lg:w-2/5 bg-white p-6 md:p-16 relative h-screen overflow-auto">
        <OnboardingHeader
          currentStep={currentStep}
          onboardingValue={onboardingValue}
          setOnboardingValue={setOnboardingValue}
        />
        <h1 className="text-2xl font-semibold text-center mb-6 mt-16 md:mt-0">
          식단을 시작할 날짜를 선택해주세요
        </h1>
        <p className="text-sm mb-8 text-gray-500">
          * 모든 식단은 일주일을 기준으로 생성됩니다.
        </p>
        <div className="calendar-container">
          <Calendar
            onChange={handleDateChange}
            value={selectedStartDate}
            formatDay={(locale, date) => date.getDate()}
            tileClassName={({ date }) => {
              if (
                selectedStartDate &&
                date >= selectedStartDate &&
                date <= getEndDate(selectedStartDate)
              ) {
                return "selected-date";
              }
              return null;
            }}
            tileDisabled={({ date }) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return date < today
            }}
            prevLabel={<span>&lsaquo;</span>}
            nextLabel={<span>&rsaquo;</span>}
            navigationLabel={({ date }) => (
              <span>{`${date.getFullYear()}년 ${date.getMonth() + 1}월`}</span>
            )}
          />
        </div>
        {selectedStartDate && (
          <div className="text-sm mb-8 text-gray-700 text-center">
            <p>
              {formatDate(selectedStartDate)} ~{" "}
              {formatDate(getEndDate(selectedStartDate))}
            </p>
            <p className="text-xs mt-1 text-gray-500">
              총 7일간의 식단이 생성됩니다.
            </p>
          </div>
        )}
        <button
          className="w-full py-2 bg-amber-400 text-gray-700 font-semibold rounded-full shadow-md"
          onClick={handleNextClick}
        >
          {location.state && location.state.fromInputCheck
            ? "변경 완료"
            : "다음으로"}
        </button>
      </div>
    </div>
  );
};

export default SelectMealDay;
